import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from '../session/withAuthorization';
import { Link, Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Navigation from '../navigation';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import _ from 'lodash';
import * as PatientActions from './PatientActions';
import RUSure from '../app/RUSure';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { typeNames } from '../../constants/maps';

function Patient(props) {
  if (props.patient === undefined) return <Redirect to={ROUTES.PATIENTS} />;

  const classes = useStyles();

  const [deleteProtocol, setDeleteProtocol] = React.useState('');
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  let protocols = [];
  let lastTherapy = null;
  let lastTherapyIndex = 0;
  let lastKinesio = null;
  let lastKinesioIndex = 0;
  let lastExam = null;
  let lastExamIndex = 0;
  let lastSomatopedIndex = 0;
  let lastLogopedIndex = 0;
  let lastPsihologIndex = 0;
  let lastEndoIndex = 0;

  _.forIn(props.patient.protocols, (value, key) => {
    if (value.pages !== undefined && value.pages.length > 0) protocols.push(value);
  });
  protocols = _.orderBy(protocols, 'date', 'desc');

  for (let index = 0; index < protocols.length; index++) {
    if (protocols[index].type === 'therapy') {
      lastTherapy = protocols[index];
      lastTherapyIndex = lastTherapy.protocol_number;
      break;
    }
  }
  for (let index = 0; index < protocols.length; index++) {
    if (protocols[index].type === 'kinesiotherapy') {
      lastKinesio = protocols[index];
      lastKinesioIndex = lastKinesio.protocol_number;
      break;
    }
  }
  for (let index = 0; index < protocols.length; index++) {
    if (protocols[index].type === 'exam') {
      lastExam = protocols[index];
      lastExamIndex = lastExam.protocol_number;
      break;
    }
  }
  for (let index = 0; index < protocols.length; index++) {
    if (protocols[index].type === 'somatoped') {
      lastSomatopedIndex = protocols[index].protocol_number;
      break;
    }
  }
  for (let index = 0; index < protocols.length; index++) {
    if (protocols[index].type === 'logoped') {
      lastLogopedIndex = protocols[index].protocol_number;
      break;
    }
  }
  for (let index = 0; index < protocols.length; index++) {
    if (protocols[index].type === 'psiholog') {
      lastPsihologIndex = protocols[index].protocol_number;
      break;
    }
  }
  for (let index = 0; index < protocols.length; index++) {
    if (protocols[index].type === 'endokrinolog') {
      lastEndoIndex = protocols[index].protocol_number;
      break;
    }
  }

  const drawProtocols = () => {
    const output = [];

    _.forIn(protocols, function(value, key) {
      output.push(
        <TableRow key={value.id}>
          <TableCell component="th" scope="row">
            <Link key={value.id} onClick={() => props.select(value.id)} to={ROUTES.NEW_PATIENT_PROTOCOL}>
              <Button color="secondary">{typeNames[value.type] + ' ' + value.protocol_number}</Button>
            </Link>
          </TableCell>
          <TableCell align="right">{moment(value.date).format('DD/MM/YYYY')}</TableCell>
          <TableCell align="right">
            <Button onClick={() => props.duplicate(value)}>
              <FilterNoneIcon />
            </Button>
            {props.userRole === 'admin' && (
              <Button
                onClick={() => {
                  setDeleteProtocol(value.id);
                  setIsDeleteOpen(true);
                }}
              >
                <DeleteOutlineIcon />
              </Button>
            )}
          </TableCell>
        </TableRow>,
      );
    });
    return output;
  };

  const continueTherapy = () => {
    const output = [];
    if (lastTherapy !== null || lastKinesio !== null)
      output.push(
        <Typography key="typo" variant="h6">
          ili nastavljamo sa prethodnom
        </Typography>,
      );
    if (lastTherapy !== null)
      output.push(
        <Link key="link1" onClick={() => props.duplicate(lastTherapy)} to={ROUTES.PATIENT_PROTOCOL}>
          <Button variant="contained" color="secondary" className={classes.fabGreen}>
            fizikalnom terapijom
          </Button>
        </Link>,
      );
    if (lastKinesio !== null)
      output.push(
        <Link key="link2" onClick={() => props.duplicate(lastKinesio)} to={ROUTES.PATIENT_PROTOCOL}>
          <Button variant="contained" color="secondary" className={classes.fabGreen}>
            kineziterapijom
          </Button>
        </Link>,
      );
    return output;
  };

  return (
    <Grid container direction="column" justify="flex-start" alignItems="stretch">
      <Navigation title={`Pacijent: ` + props.patient.name} />
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={3} className={classes.container}>
        <Grid item>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Osnovni podaci</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Broj protokola</TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      defaultValue={props.patient.protocol_number}
                      onBlur={event => props.setProperty('protocol_number', event.target.value)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ime i prezime</TableCell>
                  <TableCell>
                    <TextField fullWidth defaultValue={props.patient.name} onBlur={event => props.setProperty('name', event.target.value)} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Datum rođenja</TableCell>
                  <TableCell>
                    <KeyboardDatePicker
                      margin="none"
                      format="DD/MM/yyyy"
                      value={props.patient.birthdate}
                      onChange={date => props.setProperty('birthdate', date.valueOf())}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Pol</TableCell>
                  <TableCell>
                    <Select value={props.patient.gender} onChange={event => props.setProperty('gender', event.target.value)}>
                      <MenuItem value="Muški">Muški</MenuItem>
                      <MenuItem value="Ženski">Ženski</MenuItem>
                      <MenuItem value="Neodređen">Neodređen</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Starosno doba</TableCell>
                  <TableCell>
                    <Select value={props.patient.age} onChange={event => props.setProperty('age', event.target.value)}>
                      <MenuItem value="Baby">Beba</MenuItem>
                      <MenuItem value="Child">Dete</MenuItem>
                      <MenuItem value="Adult">Punoletno</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Adresa i broj</TableCell>
                  <TableCell>
                    <TextField fullWidth defaultValue={props.patient.address} onBlur={event => props.setProperty('address', event.target.value)} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Grad</TableCell>
                  <TableCell>
                    <TextField fullWidth defaultValue={props.patient.city} onBlur={event => props.setProperty('city', event.target.value)} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Poštanski kod</TableCell>
                  <TableCell>
                    <TextField fullWidth defaultValue={props.patient.zip} onBlur={event => props.setProperty('zip', event.target.value)} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Telefon</TableCell>
                  <TableCell>
                    <TextField fullWidth defaultValue={props.patient.phone} onBlur={event => props.setProperty('phone', event.target.value)} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>
                    <TextField fullWidth defaultValue={props.patient.email} onBlur={event => props.setProperty('email', event.target.value)} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item>
          <Paper className={classes.container}>
            <Typography variant="h6">Sa pacijentom radimo novi...</Typography>
            <Link onClick={() => props.add('exam', lastExamIndex + 1)} to={ROUTES.NEW_PATIENT_PROTOCOL}>
              <Button variant="contained" color="primary" className={classes.fabGreen}>
                Pregled
              </Button>
            </Link>

            <Link onClick={() => props.add('therapy', lastTherapyIndex + 1)} to={ROUTES.NEW_PATIENT_PROTOCOL}>
              <Button variant="contained" color="primary" className={classes.fabGreen}>
                Fizikalna terapija
              </Button>
            </Link>
            <Link onClick={() => props.add('kinesiotherapy', lastKinesioIndex + 1)} to={ROUTES.NEW_PATIENT_PROTOCOL}>
              <Button variant="contained" color="primary" className={classes.fabGreen}>
                Kineziterapija
              </Button>
            </Link>
            <Link onClick={() => props.add('somatoped', lastSomatopedIndex + 1)} to={ROUTES.NEW_PATIENT_PROTOCOL}>
              <Button variant="contained" color="primary" className={classes.fabGreen}>
                Somatoped
              </Button>
            </Link>
            <Link onClick={() => props.add('logoped', lastLogopedIndex + 1)} to={ROUTES.NEW_PATIENT_PROTOCOL}>
              <Button variant="contained" color="primary" className={classes.fabGreen}>
                Logoped
              </Button>
            </Link>
            <Link onClick={() => props.add('psiholog', lastPsihologIndex + 1)} to={ROUTES.NEW_PATIENT_PROTOCOL}>
              <Button variant="contained" color="primary" className={classes.fabGreen}>
                Psiholog
              </Button>
            </Link>
            <Link onClick={() => props.add('endokrinolog', lastEndoIndex + 1)} to={ROUTES.NEW_PATIENT_PROTOCOL}>
              <Button variant="contained" color="primary" className={classes.fabGreen}>
                Endokrinolog
              </Button>
            </Link>
            {continueTherapy()}
          </Paper>
        </Grid>

        <Grid item>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Pregledaj prethodnu posetu</Typography>
                  </TableCell>
                  <TableCell align="right">Datum</TableCell>
                  <TableCell align="right">Funkcije</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{drawProtocols()}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <RUSure isOpen={isDeleteOpen} setModal={setIsDeleteOpen} delete={() => props.delete(deleteProtocol)} />
    </Grid>
  );
}

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => ({
  user: state.user,
  patient: state.patients.patients[state.patients.currentPatient],
  protocols: state.protocols.protocols,
  currState: state,
  userRole: state.user.role,
});

const mapDispatchToProps = dispatch => ({
  setProperty: (property, value) => dispatch({ type: PatientActions.SET_PROPERTY, property: property, value }),
  select: value => dispatch({ type: PatientActions.SELECT_PROTOCOL, value }),
  add: (visitType, index) => dispatch({ type: PatientActions.ADD_PROTOCOL, visitType, index }),
  duplicate: value => dispatch({ type: PatientActions.DUPLICATE_PROTOCOL, value }),
  delete: id => dispatch({ type: PatientActions.DELETE_PROTOCOL, id }),
});

export default compose(withAuthorization(condition), connect(mapStateToProps, mapDispatchToProps))(Patient);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  longtext: {
    width: '50vh',
  },
  shorttext: {
    width: '85px',
  },
  divider: {
    width: '20px',
  },
  fabGreen: {
    borderRadius: 10,
    marginRight: '20px',
  },
}));
