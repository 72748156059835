import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import ProtocolOverviewItem from '../../../patient/ProtocolOverviewItem';

export default function MultipleChoiceView(props) {
  const drawAnswers = () => {
    const answers = [];
    for (let index = 0; index < props.answers.length; index++) {
      const element = props.answers[index];
      answers.push(
        <FormControlLabel
          key={props.index + ':' + index}
          value={element.index}
          control={<Radio />}
          label={element.answer}
          checked={props.patientAnswer === index}
        />,
      );
    }
    return answers;
  };

  return (
    <RadioGroup aria-label="gender" name="gender1" onChange={event => props.answer({ index: props.index, answer: parseInt(event.target.value) })}>
      {drawAnswers()}
    </RadioGroup>
  );
}

export function MultipleChoiceDiagnosis(props) {
  if (props.patientAnswer !== undefined && props.patientAnswer !== '')
    return <ProtocolOverviewItem question={props.question} answer={props.answers[props.patientAnswer].answer} />;
  else return null;
}
