import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
//import 'firebase/analytics';

/**
 * 
 CONFIG LIVE
 **/
const config = {
  apiKey: 'AIzaSyBsvoeDVlkeaOYRhQqgRGN4E176FjH-ep8',
  authDomain: 'diagnika.firebaseapp.com',
  databaseURL: 'https://diagnika.firebaseio.com',
  projectId: 'diagnika',
  storageBucket: 'diagnika.appspot.com',
  messagingSenderId: '29819804321',
  appId: '1:29819804321:web:8940de91694eca61a32ee0',
};
/**/

/**
 * 
 CONFIG STAGING
 *//*
const config = {
  apiKey: "AIzaSyAqFx3wR9Y85yiwo6ZlwEiA1i6kvKRnMYY",
  authDomain: "diagnika-staging.firebaseapp.com",
  databaseURL: "https://diagnika-staging-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "diagnika-staging",
  storageBucket: "diagnika-staging.appspot.com",
  messagingSenderId: "945357641791",
  appId: "1:945357641791:web:6998cfab6058c34beb408a"
}
/**/

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    //this.analytics = app.analytics();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: 'https://diagnika.web.app/',
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        next(authUser);
      } else {
        fallback();
      }
    });

  // *** API ***

  ref = ref => this.db.ref(ref);

  backup = datetime => this.db.ref(`backups/${datetime}`);
  backupsList = () => this.db.ref(`backups/backupsList`);
  setBackupsList = date => this.db.ref(`backups/backupsList/${date}`);

  //deleteAllBackups = () => this.db.ref(`backups`).remove();

  deleteBackup = (id, name) => {
    this.db.ref(`backups/${name}`).remove();
    this.db.ref(`backups/backupsList/${id}`).remove();
  };

  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');

  lastPatientsUpdate = () => this.db.ref('/patients/lastUpdate');
  patients = () => this.db.ref('/patients');

  lastProtocolsUpdate = () => this.db.ref('/protocols/lastUpdate');
  protocols = () => this.db.ref('/protocols');

  config = () => this.db.ref('config');
}

export default Firebase;
