import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from '../session/withAuthorization';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {
  Grid,
  Container,
  IconButton,
  Checkbox,
  Fab,
  Toolbar,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import TuneIcon from '@material-ui/icons/Tune';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import * as PatientsActions from '../patient/PatientActions';
import * as ProtocolActions from '../protocol/ProtocolActions';
import * as AppActions from '../app/AppActions';
import Navigation from '../navigation';
import _ from 'lodash';
import RUSure from '../app/RUSure';

function Backups(props) {
  const [backups, setBackups] = React.useState([]);
  if (backups.length === 0) {
    props.firebase.backupsList().once('value', snapshot => {
      const output = [];
      snapshot.forEach(childSnapshot => {
        output.push({ id: childSnapshot.key, value: childSnapshot.val() });
      });
      setBackups(output);
    });
  }

  const drawBackups = () => {
    const output = [];

    _.forEach(backups, value => {
      output.push(
        <TableRow key={value.id}>
          <TableCell component="th" scope="row">
            {value.value}
          </TableCell>
          <TableCell align="right">
            <Button
              onClick={() => {
                downloadBackup(value);
              }}
            >
              <CloudDownloadIcon />
            </Button>
            <Button
              onClick={() => {
                setDeleteProtocol(value);
                setIsDeleteOpen(true);
              }}
            >
              <DeleteOutlineIcon />
            </Button>
          </TableCell>
        </TableRow>,
      );
    });
    return output;
  };

  const [deleteProtocol, setDeleteProtocol] = React.useState({});
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  const deleteBackup = value => {
    props.firebase.deleteBackup(value.id, value.value);
    setBackups([]);
  };

  const downloadBackup = value => {
    props.firebase.backup(value.value).once('value', snapshot => {
      const state = snapshot.val();
      state.protocols.lastUpdate = moment().valueOf();
      state.patients.lastUpdate = moment().valueOf();
      props.loadProtocols(state.protocols);
      props.loadPatients(state.patients);
      props.showSnack('success', 'BACKUP LOADED!');
    });
  };

  return (
    <Container maxWidth="xl">
      <Navigation title="Backups" />

      <Grid container direction="column" justify="flex-start" alignItems="stretch">
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Backup dates</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{drawBackups()}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <RUSure isOpen={isDeleteOpen} setModal={setIsDeleteOpen} delete={() => deleteBackup(deleteProtocol)} />
    </Container>
  );
}

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  showSnack: (severity, message) => dispatch({ type: AppActions.SET_SNACK, isOpen: true, severity, message }),
  loadPatients: value => dispatch({ type: PatientsActions.LOAD_PATIENTS, value }),
  loadProtocols: value => dispatch({ type: ProtocolActions.LOAD_PROTOCOLS, value }),
});

export default compose(withAuthorization(condition), connect(mapStateToProps, mapDispatchToProps))(Backups);
