import { Checkbox, FormControlLabel, FormGroup, Paper, Grid, Typography, makeStyles, Box, Button } from '@material-ui/core';
import React from 'react';
import ProtocolOverviewItem from '../../../patient/ProtocolOverviewItem';
import InstrumentTableView from '../instrumenttable/InstrumentTableView';
import InstrumentTableDiagnosis from '../instrumenttable/InstrumentTableDiagnosis';

export default function CheckboxesView(props) {
  const classes = useStyles();
  const patientAnswer = props.patientAnswer === undefined ? { 0: false } : props.patientAnswer;

  const handleChange = event => {
    const newPatientAnswer = { ...patientAnswer };
    newPatientAnswer[parseInt(event.target.name)] = event.target.checked;
    props.answer({ index: props.index, answer: newPatientAnswer });
  };

  const handleAllCheckedClick = event => {
    const newPatientAnswer = { ...patientAnswer };
    if (isAllChecked()) {
      for (let index = 0; index < props.answers.length; index++) {
        newPatientAnswer[index] = false;
      }
    } else {
      for (let index = 0; index < props.answers.length; index++) {
        newPatientAnswer[index] = true;
      }
    }
    props.answer({ index: props.index, answer: newPatientAnswer });
  };

  const drawAnswers = () => {
    if (props.answers === undefined) return [];
    const answers = [];
    for (let index = 0; index < props.answers.length; index++) {
      const element = props.answers[index];
      let editorField = (
        <FormControlLabel
          key={props.index + ':' + index}
          control={<Checkbox name={index.toString()} checked={patientAnswer[index] === true} color="primary" onChange={handleChange} />}
          label={element.answer}
        />
      );
      if (element.answer.table !== undefined) {
        editorField = (
          <Grid container direction="row" justify="flex-start" className={classes.borders}>
            <Grid item>
              <Checkbox
                className={classes.checkmarkOfset}
                name={index.toString()}
                checked={patientAnswer[index] === true}
                color="primary"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={11}>
              <InstrumentTableView {...element.answer} answer={() => {}} />
            </Grid>
          </Grid>
        );
      }
      answers.push(editorField);
    }
    return answers;
  };

  const isAllChecked = () => {
    for (let index = 0; index < props.answers.length; index++) {
      if (patientAnswer[index] !== true) return false;
    }
    return true;
  };

  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox checked={isAllChecked()} onChange={handleAllCheckedClick} />} label="Izaberi sve" />
      {drawAnswers()}
    </FormGroup>
  );
}

export function CheckboxesDiagnosis(props) {
  const classes = useStyles();

  if (props.patientAnswer !== undefined && props.patientAnswer !== '') {
    let answers = [];
    for (let index = 0; index < props.answers.length; index++) {
      if (props.patientAnswer[index] === true) {
        let diagField = <Typography variant="body2">{props.answers[index].answer}</Typography>;
        if (props.answers[index].answer.table !== undefined) {
          diagField = <InstrumentTableDiagnosis {...props.answers[index].answer} />;
        }

        answers.push(diagField);
      }
    }
    return (
      <Paper className={classes.container}>
        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
          <Typography variant="body1">
            <b>{props.question}</b>
          </Typography>
          {answers}
        </Grid>
      </Paper>
    );
  } else return null;
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  borders: {},
  checkmarkOfset: {
    marginLeft: -12,
  },
}));
