import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, passwordOne } = this.state;
    const isAdmin = false;

    const patients = 1;
    const sessions = 1;

    //extract therapistId from email adress
    const regex = /^(.+)@/gm;
    const therapistRole = email.split(regex)[1];

    const patientsData = [];

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          uid: authUser.user.uid,
          role: therapistRole,
          email,
        });
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE, success: true });
        //this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { email, passwordOne, passwordTwo, error, success } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || email === '';

    return (
      <Container>
        {success && <h4>New therapist account successfully created!</h4>}
        <h3>Add New Therapist</h3>
        <form onSubmit={this.onSubmit}>
          <input name="email" value={email} onChange={this.onChange} type="text" placeholder="therapistId@bestforcan.de" />
          <br />
          <br />
          <input name="passwordOne" value={passwordOne} onChange={this.onChange} type="text" placeholder="Password" />
          <br />
          <input name="passwordTwo" value={passwordTwo} onChange={this.onChange} type="text" placeholder="Confirm Password" />
          <br />
          <br />
          <button disabled={isInvalid} type="submit">
            Create
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </Container>
    );
  }
}

export default compose(withRouter, withFirebase)(SignUpPage);
