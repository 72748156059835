import { createTransform } from 'redux-persist';
import firebase from 'firebase/app';
import _ from 'lodash';
import moment from 'moment';

// prevent first rehydration to be saved to FB,
// so we can check lastUpdate and fetch state from FB first
let hasRehydrated = false;

const SetTransform = createTransform(
  // save state to firebaseon its way to being serialized and persisted.

  (inboundState, key) => {
    return { ...inboundState };
    const newState = { ...inboundState };

    /**/
    if (process.env.NODE_ENV !== 'production') {
      console.log('DEVELOPMENT MODE: Not saving to FB!!! Sent State: saveToFirebase:' + newState.saveToFirebase, key, newState);
      return newState;
    }
    /**/

    const onError = err => {
      if (err) {
        console.log('Writing to Firebase error: ', err);
        throw new Error('Failed to write to Firebase: ' + err);
      }
    };

    if (key === '_persist') {
      hasRehydrated = true;
      return newState;
    }

    if (!hasRehydrated) return newState;

    //console.log('SAVING TO FB!!! ' + newState.saveToFirebase, key, newState);
    /*
    switch (key) {
      case 'protocols':
        if (newState.saveToFirebase) {
          newState.saveToFirebase = false;
          firebase
            .database()
            .ref('/protocols/protocols/' + newState.currentProtocol)
            .set(newState.protocols[newState.currentProtocol], onError)
            .then(() =>
              firebase
                .database()
                .ref('/protocols/lastUpdate')
                .set(newState.lastUpdate, onError),
            );
        }
        break;

      case 'user':
        break;

      case 'patients':
        if (newState.saveToFirebase) {
          newState.saveToFirebase = false;
          firebase
            .database()
            .ref('/patients/patients/' + newState.currentPatient)
            .set(newState.patients[newState.currentPatient], onError)
            .then(() =>
              firebase
                .database()
                .ref('/patients/lastUpdate')
                .set(newState.lastUpdate, onError),
            );
        }
        break;

      default:
    }
    */

    return newState;
  },
);

export default SetTransform;
