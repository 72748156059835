import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';

export default function DateTimeAdmin(props) {
  return (
    <KeyboardDatePicker
      margin="normal"
      format="DD/MM/yyyy"
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      {...props.config}
    />
  );
}
