import {
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Navigation from '../navigation';
import withAuthorization from '../session/withAuthorization';
import * as PatientActions from './PatientActions';
import ProtocolPages from './PatientProtocolNavigation';
import * as Components from '../protocol/components/Components';
import { Redirect, Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { typeNames } from '../../constants/maps';

function FinishPatientProtocol(props) {
  if (props.protocol === undefined) return <Redirect to={ROUTES.PATIENTS} />;

  const classes = useStyles();

  const diagnosisItems = () => {
    const output = [];
    for (let index = 0; index < props.protocol.pages.length; index++) {
      const page = props.protocol.pages[index];

      if (page.questions !== undefined)
        for (let qIndex = 0; qIndex < page.questions.length; qIndex++) {
          const question = page.questions[qIndex];
          const item = Components.getDiagnosis(question);
          if (item !== null)
            output.push(
              <TableRow>
                <TableCell>{item}</TableCell>
              </TableRow>,
            );
        }
    }
    return output;
  };

  return (
    <Grid container direction="column" justify="flex-start" alignItems="center">
      <Navigation title={props.patient.name + ' : ' + typeNames[props.protocol.type] + ' ' + props.protocol.protocol_number} />
      <div style={{ height: 40 }} />
      <Typography align="center" variant="h4">
        Zaključak posete
      </Typography>

      <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch" className={classes.container}>
        <Grid item>
          <Paper elevation={3} className={classes.container}>
            <Typography variant="h5">Izveštaj urađenog programa</Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{diagnosisItems()}</TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        {props.protocol.type === 'exam' && (
          <Grid item>
            <Paper elevation={3} className={classes.container}>
              <Typography variant="h5">Diagnosis</Typography>
              <TextField
                fullWidth
                multiline
                defaultValue={props.protocol.diagnosis}
                onBlur={event => props.setProtocolProperty(event.target.value,'diagnosis')}
              />
            </Paper>
          </Grid>
        )}
        <Grid item>
          <Paper elevation={3} className={classes.container}>
            <Typography variant="h5">Zaključak</Typography>
            <TextField
              fullWidth
              multiline
              defaultValue={props.protocol.conclusion}
              onBlur={event => props.setProtocolProperty(event.target.value,'conclusion')}
            />
          </Paper>
        </Grid>
        {props.protocol.type === 'exam' && (
          <Grid item>
            <Paper elevation={3} className={classes.container}>
              <Typography variant="h5">Terapija</Typography>
              <TextField
                fullWidth
                multiline
                defaultValue={props.protocol.therapy}
                onBlur={event => props.setProtocolProperty(event.target.value,'therapy')}
              />
            </Paper>
          </Grid>
        )}
        <Grid item>
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Link to={ROUTES.PATIENT}>
                <Button className={classes.fabGreen}>Završi posetu</Button>
              </Link>
            </Grid>
            <Grid item>
              <Link className={classes.print} to={ROUTES.PRINT_PATIENT_PROTOCOL}>
                <Button variant="contained" className={classes.roundedButton}>
                  Odštampaj izveštaj
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ProtocolPages />
    </Grid>
  );
}

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => {
  if (state.patients.currentPatient === '' || state.patients.currentProtocol === '')
    return { currState: state, protocol: undefined, patient: { name: '' } };

  return {
    protocol: state.patients.patients[state.patients.currentPatient].protocols[state.patients.currentProtocol],
    currState: state,
    patient: state.patients.patients[state.patients.currentPatient],
    currentProtocol: state.patients.currentProtocol,
  };
};

const mapDispatchToProps = dispatch => ({
  setProtocol: value => dispatch({ type: PatientActions.SET_PROTOCOL, value }),
  setProtocolProperty: (value, pproperty) => dispatch({ type: PatientActions.SET_PROTOCOL_PROPERTY, value, pproperty }),
});

export default compose(withAuthorization(condition), connect(mapStateToProps, mapDispatchToProps))(FinishPatientProtocol);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
  },
  buttonContainer: {
    paddingLeft: theme.spacing(3),
  },
  outline: {
    borderStyle: 'solid',
  },
  print: {
    paddingTop: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabGreen: {
    borderRadius: 10,
    color: theme.palette.common.white,
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[600],
    },
    padding: theme.spacing(2),
  },
  formControl: {
    minWidth: 250,
  },
  roundedButton: {
    borderRadius: 10,
  },
}));
