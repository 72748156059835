import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from '../session/withAuthorization';
import { Grid, Paper, Typography, Button, TextField, FormControl, Select, InputLabel } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Navigation from '../navigation';
import _ from 'lodash';
import * as PatientActions from './PatientActions';
import * as Components from '../protocol/components/Components';
import ProtocolPages from './PatientProtocolNavigation';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ReactDragListView from 'react-drag-listview';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HeightIcon from '@material-ui/icons/Height';
import RUSure from '../app/RUSure';
import { typeNames } from '../../constants/maps';

function Question(props) {
  const classes = useStyles();
  const [isHover, setIsHover] = React.useState(false);

  const drawViewComponent = question => {
    const config = { ...question };
    config.answer = props.answer;
    return Components.getComponentView(config);
  };

  return (
    <Paper
      className={classes.container}
      onClick={() => setIsHover(true)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Typography variant="h6" gutterBottom>
        {props.question.question}
      </Typography>
      {drawViewComponent(props.question)}
      {isHover && (
        <Paper className={classes.hoverView}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <a href="#">
              <Button>
                <HeightIcon />
              </Button>
            </a>
            <Button
              onClick={() => {
                props.selectQuestion(props.question.index);
              }}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => {
                props.setDeleteQuestionID(props.question.index);
                props.setIsDeleteOpen(true);
              }}
            >
              <DeleteOutlineIcon />
            </Button>
          </Grid>
        </Paper>
      )}
    </Paper>
  );
}

function AdminQuestion(props) {
  const classes = useStyles();

  const drawAdminComponent = () => {
    return Components.getComponentAdmin(props.question, props.setProperty);
  };

  return (
    <Paper elevation={3} className={classes.container}>
      <Grid container direction="column" justify="flex-start" alignItems="stretch">
        <Grid item>
          <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
            <Grid item xs={6}>
              <TextField
                required
                key={props.keyPrefix + 'text'}
                label="Question"
                placeholder="Enter question text"
                variant="outlined"
                fullWidth
                defaultValue={props.question.question}
                onBlur={event => props.setProperty('question', event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl variant="outlined">
                <InputLabel id="outlined-label">Type</InputLabel>
                <Select
                  fullWidth
                  labelId="outlined-label"
                  label="Type"
                  defaultValue={props.question.type}
                  onBlur={event => {
                    props.setProperty('patientAnswer', '');
                    props.setProperty('type', event.target.value);
                  }}
                >
                  {Components.COMPONENTS_SELECT_LIST}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{drawAdminComponent()}</Grid>
      </Grid>
      <Paper className={classes.hoverViewClose}>
        <Button
          onClick={() => {
            props.selectQuestion(-1);
          }}
        >
          <HighlightOffIcon />
        </Button>
      </Paper>
    </Paper>
  );
}

function PatientProtocol(props) {
  if (props.protocol === undefined) return <Redirect to={ROUTES.PATIENTS} />;

  const classes = useStyles();

  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [deleteQuestionID, setDeleteQuestionID] = React.useState(0);

  const questions = () => {
    const output = [];
    _.forEach(props.protocol.pages[props.currentPage].questions, function(value) {
      output.push(
        <li key={props.currentProtocol + ':' + props.currentPage + ':' + value.index} style={{ listStyleType: 'none', marginBottom: 25 }}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item className={classes.containerMax}>
              {props.currentQuestion != value.index && (
                <Question
                  question={value}
                  setDeleteQuestionID={setDeleteQuestionID}
                  setIsDeleteOpen={setIsDeleteOpen}
                  selectQuestion={props.selectQuestion}
                  answer={props.answer}
                />
              )}
              {props.currentQuestion == value.index && (
                <AdminQuestion question={value} setProperty={props.setProperty} selectQuestion={props.selectQuestion} />
              )}
            </Grid>
          </Grid>
        </li>,
      );
    });
    return output;
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      console.log(fromIndex, toIndex);

      const data = props.protocol.pages[props.currentPage].questions;
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.index = index;
      }
      props.setProtocol(_.cloneDeep(props.protocol));
    },
    nodeSelector: 'li',
    handleSelector: 'a',
  };

  return (
    <Grid container direction="column" justify="flex-start" alignItems="center">
      <Navigation title={props.patient.name + ' : ' + typeNames[props.protocol.type] + ' ' + props.protocol.protocol_number} />
      <div style={{ height: 40 }} />
      <Typography align="center" variant="h4">
        {props.protocol.pages[props.currentPage].name}
      </Typography>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3} className={classes.container}>
        <ReactDragListView {...dragProps}>{questions()}</ReactDragListView>
      </Grid>
      <RUSure isOpen={isDeleteOpen} setModal={setIsDeleteOpen} delete={() => props.removeQuestion(deleteQuestionID)} />
      <ProtocolPages />
    </Grid>
  );
}

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => {
  if (state.patients.currentPatient === '' || state.patients.currentProtocol === '') return { protocol: undefined };

  return {
    protocol: state.patients.patients[state.patients.currentPatient].protocols[state.patients.currentProtocol],
    patient: state.patients.patients[state.patients.currentPatient],
    currentPage: state.patients.currentPage,
    currentProtocol: state.patients.currentProtocol,
    currentQuestion: state.patients.currentQuestion,
  };
};

const mapDispatchToProps = dispatch => ({
  answer: value => dispatch({ type: PatientActions.ANSWER_QUESTION, value }),
  setProtocol: value => dispatch({ type: PatientActions.SET_PROTOCOL, value }),
  removeQuestion: value => dispatch({ type: PatientActions.REMOVE_QUESTION, value }),
  selectQuestion: value => dispatch({ type: PatientActions.SELECT_QUESTION, value }),
  setProperty: (property, value) => dispatch({ type: PatientActions.SET_QUESTION_PROPERTY, property: property, value }),
});

export default compose(withAuthorization(condition), connect(mapStateToProps, mapDispatchToProps))(PatientProtocol);

const useStyles = makeStyles(theme => ({
  hoverView: {
    border: 'solid',
    borderColor: '#888888',
    backgroundColor: '#CCCCCC',
    position: 'absolute',
    top: 0,
    right: 0,
    width: 200,
    height: 45,
  },
  hoverViewClose: {
    position: 'absolute',
    textAlign: 'right',
    top: 0,
    right: 0,
    width: 70,
    height: 45,
  },
  container: {
    padding: theme.spacing(3),
  },
  containerMax: {
    position: 'relative',
    maxWidth: '96vw',
    flexGrow: 1,
  },
  buttonContainer: {
    paddingLeft: theme.spacing(3),
  },
  outline: {
    borderStyle: 'solid',
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));
