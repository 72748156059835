import { Button, Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ReactDragListView from 'react-drag-listview';
import React from 'react';
import InstrumentTableView from './InstrumentTableView';

export default function InstrumentTableAdmin(props) {
  if (props.table === undefined) {
    props.setProperty('table', {
      rows: [{ index: 0, label: 'Row 1' }],
      columns: [{ index: 0, label: 'Column 1', type: 'textfield' }],
    });
    return <div />;
  }

  const add = data => {
    data.push({ index: data.length, label: `Label ${data.length}`, type: 'textfield' });
    props.setProperty('table', props.table);
  };
  const del = (index, data) => {
    data.splice(index, 1);
    for (let index = 0; index < data.length; index++) {
      data[index].index = index;
    }
    props.setProperty('table', props.table);
  };
  const moveDown = (index, data) => {
    const prevItem = { ...data[index] };
    const nextItem = { ...data[index + 1] };
    prevItem.index += 1;
    nextItem.index -= 1;
    data[index + 1] = prevItem;
    data[index] = nextItem;
    props.setProperty('table', props.table);
  };
  const moveUp = (index, data) => {
    const prevItem = { ...data[index] };
    const nextItem = { ...data[index - 1] };
    prevItem.index -= 1;
    nextItem.index += 1;
    data[index - 1] = prevItem;
    data[index] = nextItem;
    props.setProperty('table', props.table);
  };
  const changeLabel = (index, value, data) => {
    data[index].label = value;
    props.setProperty('table', props.table);
  };
  const changeType = (index, value) => {
    props.table.columns[index].type = value;
    props.setProperty('table', props.table);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      console.log(fromIndex, toIndex);

      const data = props.table.rows;
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.index = index;
      }
      props.setProperty('table', props.table);
    },
    nodeSelector: 'li',
    handleSelector: 'a',
  };

  const dragPropsColumns = {
    onDragEnd(fromIndex, toIndex) {
      console.log(fromIndex, toIndex);

      const data = props.table.columns;
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.index = index;
      }
      props.setProperty('table', props.table);
    },
    nodeSelector: 'li',
    handleSelector: 'a',
  };

  const rows = () => {
    const output = [];
    for (let index = 0; index < props.table.rows.length; index++) {
      const row = props.table.rows[index];
      output.push(
        <li key={index} style={{ listStyleType: 'none' }}>
          <Grid item key={'adminRow' + props.index + ':' + index}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <a href="#">
                <DragIndicatorIcon />
              </a>
              <Grid item xs={11}>
                <TextField
                  required
                  placeholder="Enter row label"
                  variant="outlined"
                  fullWidth
                  value={row.label}
                  onChange={event => changeLabel(row.index, event.target.value, props.table.rows)}
                />
              </Grid>
              <Grid item>
                <Toolbar data={props.table.rows} currentObject={row} delete={del} moveDown={moveDown} moveUp={moveUp} />
              </Grid>
            </Grid>
          </Grid>
        </li>,
      );
    }
    return output;
  };

  const columns = () => {
    const output = [];
    for (let index = 0; index < props.table.columns.length; index++) {
      const column = props.table.columns[index];
      output.push(
        <li key={index} style={{ listStyleType: 'none' }}>
          <Grid item key={'adminColumn' + props.index + ':' + index}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <a href="#">
                <DragIndicatorIcon />
              </a>
              <Grid item xs={9}>
                <TextField
                  required
                  placeholder="Enter column label"
                  variant="outlined"
                  fullWidth
                  value={column.label}
                  onChange={event => changeLabel(column.index, event.target.value, props.table.columns)}
                />
              </Grid>
              <Grid item>
                <Select value={column.type} onChange={event => changeType(column.index, event.target.value)}>
                  <MenuItem value="textfield">Text</MenuItem>
                  <MenuItem value="checkbox">Checkbox</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <Toolbar data={props.table.columns} currentObject={column} delete={del} moveDown={moveDown} moveUp={moveUp} />
              </Grid>
            </Grid>
          </Grid>
        </li>,
      );
    }
    return output;
  };

  return (
    <Grid container direction="column" justify="space-between" alignItems="stretch">
      <Grid item>
        <Typography variant="h6">Columns</Typography>
        <ReactDragListView {...dragPropsColumns}>{columns()}</ReactDragListView>
        <Grid item>
          <Button onClick={() => add(props.table.columns)}>
            <AddIcon /> Add Column
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h6">Rows</Typography>
        <ReactDragListView {...dragProps}>{rows()}</ReactDragListView>
        <Grid item>
          <Button onClick={() => add(props.table.rows)}>
            <AddIcon /> Add Row
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <InstrumentTableView
          {...props}
          answer={answer => {
            props.setProperty('tableData', answer.answer);
          }}
        />
      </Grid>
    </Grid>
  );
}

function Toolbar(props) {
  return (
    <Grid item>
      <Button onClick={() => props.delete(props.currentObject.index, props.data)}>
        <DeleteOutlineIcon />
      </Button>
    </Grid>
  );
}
