import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';
import moment from 'moment';
import ProtocolOverviewItem from '../../../patient/ProtocolOverviewItem';

export default function DateTimeView(props) {
  const today = new Date();
  const patientAnswer = props.patientAnswer === undefined ? today.valueOf() : props.patientAnswer;
  return (
    <KeyboardDatePicker
      margin="normal"
      format="DD/MM/yyyy"
      value={patientAnswer}
      onChange={date => props.answer({ index: props.index, answer: date.valueOf() })}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  );
}

export function DateDiagnosis(props) {
  if (props.patientAnswer !== undefined && props.patientAnswer !== '') {
    const today = new Date(props.patientAnswer);
    return <ProtocolOverviewItem question={props.question} answer={moment(today).format('DD/MM/yyyy')} />;
  } else return null;
}
