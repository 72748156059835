import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default function TableView(props) {
  const handleChange = (value, row, column) => {
    const newPatientAnswer = props.patientAnswer === undefined ? {} : { ...props.patientAnswer };
    const rowIndex = 'r' + row.index;
    const columnIndex = 'c' + column.index;
    if (newPatientAnswer[rowIndex] === undefined) newPatientAnswer[rowIndex] = {};
    newPatientAnswer[rowIndex][columnIndex] = value;
    props.answer({ index: props.index, answer: newPatientAnswer });
  };

  const drawCell = (row, column) => {
    let value = '';
    if (props.patientAnswer === undefined) value = undefined;
    if (value !== undefined) {
      value =
        props.patientAnswer[row.index] === undefined || props.patientAnswer[row.index] === null
          ? undefined
          : props.patientAnswer[row.index][column.index];
      //FIX TO A NEW FORMAT FOR PATIENT ANSWER. IT WAS AN ARRAY AND NOW IS AN OBJECT!!!
      if (value === undefined)
        value = props.patientAnswer['r' + row.index] === undefined ? '' : props.patientAnswer['r' + row.index]['c' + column.index];
    }

    if (column.type === 'textfield')
      return (
        <TextField
          size="small"
          key={props.index + ':' + row.index + ':' + column.index + ':text'}
          variant="outlined"
          value={value}
          onChange={event => handleChange(event.target.value, row, column)}
        />
      );
    if (column.type === 'dropdown')
      return (
        <Select value={value} onChange={event => handleChange(event.target.value, row, column)}>
          <MenuItem value="0">0</MenuItem>
        </Select>
      );
    let isChecked = value == 'true' || value === true;
    return (
      <Checkbox
        key={props.index + ':' + row.index + ':' + column.index + ':check'}
        color="primary"
        checked={isChecked}
        onChange={event => handleChange(event.target.checked, row, column)}
      />
    );
  };

  const drawRows = () => {
    const rows = [];

    for (let index = 0; index < props.table.rows.length; index++) {
      const rowData = props.table.rows[index];
      const cells = [];
      for (let cellIndex = 0; cellIndex < props.table.columns.length; cellIndex++) {
        const column = props.table.columns[cellIndex];
        cells.push(
          <TableCell key={props.index + ':' + rowData.index + ':' + cellIndex + ':cell'} align="center" size="small">
            {drawCell(rowData, column)}
          </TableCell>,
        );
      }
      rows.push(
        <TableRow key={props.index + ':' + rowData.index}>
          <TableCell>{rowData.label}</TableCell>
          {cells}
        </TableRow>,
      );
    }

    return rows;
  };

  const drawHeaderCells = () => {
    const output = [];
    for (let index = 0; index < props.table.columns.length; index++) {
      const element = props.table.columns[index];
      output.push(
        <TableCell key={props.index + ':' + index} align="center">
          {element.label}
        </TableCell>,
      );
    }
    return output;
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 250 }}></TableCell>
            {drawHeaderCells()}
          </TableRow>
        </TableHead>
        <TableBody>{drawRows()}</TableBody>
      </Table>
    </TableContainer>
  );
}

export function TableDiagnosis(props) {
  if (props.patientAnswer === undefined) return null;
  const patientAnswer = props.patientAnswer;

  const newTable = { rows: [], columns: props.table.columns };
  for (let index = 0; index < props.table.rows.length; index++) {
    const rowData = props.table.rows[index];
    let hasCells = false;
    for (let cellIndex = 0; cellIndex < props.table.columns.length; cellIndex++) {
      const column = props.table.columns[cellIndex];
      let value =
        props.patientAnswer !== undefined && props.patientAnswer[index] !== undefined && props.patientAnswer[index] !== null
          ? props.patientAnswer[index][cellIndex]
          : undefined;

      //FIX TO A NEW FORMAT FOR PATIENT ANSWER. IT WAS AN ARRAY AND NOW IS AN OBJECT!!!
      if (value === undefined)
        value =
          props.patientAnswer !== undefined && props.patientAnswer['r' + index] !== undefined
            ? props.patientAnswer['r' + index]['c' + cellIndex]
            : undefined;

      if (column.type === 'textfield') {
        if (value !== null && value !== undefined && value !== '') {
          hasCells = true;
          break;
        }
      }
      if (column.type === 'checkbox') {
        if (value === true) {
          hasCells = true;
          break;
        }
      }
    }
    if (hasCells) {
      newTable.rows.push(rowData);
    }
  }

  const drawCell = (row, column) => {
    let value = patientAnswer[row.index] === undefined || patientAnswer[row.index] === null ? '' : patientAnswer[row.index][column.index];
    //FIX TO A NEW FORMAT FOR PATIENT ANSWER. IT WAS AN ARRAY AND NOW IS AN OBJECT!!!
    if (value === '') value = patientAnswer['r' + row.index] === undefined ? '' : patientAnswer['r' + row.index]['c' + column.index];

    if (column.type === 'textfield') return <Typography variant="caption">{value}</Typography>;
    let isChecked = value == 'true' || value === true;
    if (isChecked) return <Typography variant="caption">{column.label}</Typography>;
  };

  const drawRows = () => {
    const rows = [];

    for (let index = 0; index < newTable.rows.length; index++) {
      const rowData = newTable.rows[index];
      const cells = [];
      for (let cellIndex = 0; cellIndex < newTable.columns.length; cellIndex++) {
        const column = newTable.columns[cellIndex];
        cells.push(
          <TableCell key={props.index + ':' + rowData.index + ':' + cellIndex + ':cell'} align="center">
            {drawCell(rowData, column)}
          </TableCell>,
        );
      }
      rows.push(
        <TableRow key={props.index + ':' + rowData.index}>
          <TableCell>{rowData.label}</TableCell>
          {cells}
        </TableRow>,
      );
    }

    return rows;
  };

  const drawHeaderCells = () => {
    const output = [];
    for (let index = 0; index < newTable.columns.length; index++) {
      const element = newTable.columns[index];
      const label = element.type === 'textfield' ? element.label : '';
      output.push(
        <TableCell key={props.index + ':' + index} align="center">
          {label}
        </TableCell>,
      );
    }
    return output;
  };

  if (newTable.rows.length === 0) return null;
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body1">
                <b>{props.question}</b>
              </Typography>
            </TableCell>
            {drawHeaderCells()}
          </TableRow>
        </TableHead>
        <TableBody>{drawRows()}</TableBody>
      </Table>
    </TableContainer>
  );
}
