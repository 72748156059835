import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../firebase';
import * as ROUTES from '../../constants/routes';
import * as UserActions from '../user/UserActions';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      const notLoggedIn = () => {
        this.props.setUser({ isLoggedIn: false });
        this.props.history.push(ROUTES.SIGN_IN);
      };

      this.listener = this.props.firebase.onAuthUserListener(authUser => {
        if (!condition(authUser)) notLoggedIn();
        else if (!this.props.user.isLoggedIn) {
          let role = 'admin';
          this.props.firebase.user(authUser.uid).on('value', snapshot => {
            console.log(snapshot.val());
            this.props.setUser({ role: snapshot.val().role, isLoggedIn: true });
          });
        }
      }, notLoggedIn);
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return this.props.user.isLoggedIn ? <Component {...this.props} /> : null;
    }
  }

  const mapStateToProps = state => ({
    user: state.user,
  });

  const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch({ type: UserActions.SET_USER, user }),
  });

  return compose(withRouter, withFirebase, connect(mapStateToProps, mapDispatchToProps))(WithAuthorization);
};

export default withAuthorization;
