import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as ROUTES from '../../constants/routes';
import SignOutButton from '../session/SignOut';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton, Button, Grid, Box } from '@material-ui/core';
import { CloudDownload, Menu } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssistantIcon from '@material-ui/icons/Assistant';
import * as AppActions from '../app/AppActions';
import * as ProtocolActions from '../protocol/ProtocolActions';
import * as PatientActions from '../patient/PatientActions';
import moment from 'moment';
import _ from 'lodash';
import { withFirebase } from '../firebase';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    minWidth: 230,
    padding: theme.spacing(5),
  },
}));

function Navigation(props) {
  const classes = useStyles();
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = () => (
    <Grid container direction="column" className={classes.drawer} onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <Typography variant="h6" align="center">
        PHYSIO PRO
      </Typography>
      <Typography align="center">v23.01.13</Typography>
      <Box style={{ height: 20 }}> </Box>
      <Divider variant="middle" />

      <Link to={ROUTES.PATIENTS} key="Patients">
        <Button color="secondary">
          <AssignmentIndIcon /> Pacijenti
        </Button>
      </Link>
      <Link to={ROUTES.PATIENT} key="Patient">
        <Button color="secondary">
          <AssistantIcon /> {props.currentPatientName}
        </Button>
      </Link>
      <Divider variant="middle" />
      <Link to={ROUTES.ADMIN_PROTOCOLS} key="Admin Protocols">
        <Button color="secondary">
          <AssignmentIcon /> Administracija
        </Button>
      </Link>
      
      <Divider variant="middle" />
      <Box style={{ height: 20 }}> </Box>
      <Box style={{ height: 20 }}> </Box>
      <SignOutButton />
    </Grid>
  );

  return (
    <div style={{ height: 70 }}>
      <AppBar position="fixed" color="secondary" className={classes.root}>
        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
        <Toolbar>
          <IconButton onClick={toggleDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <Menu />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {props.title}
          </Typography>
          <Link to={ROUTES.PATIENT} key="Patient">
            <Button style={{ color: 'white' }}>
              <AssistantIcon /> {props.currentPatientName}
            </Button>
          </Link>
          <Link to={ROUTES.PATIENTS} key="Patients">
            <Button style={{ color: 'white' }}>
              <AssignmentIndIcon /> Pacijenti
            </Button>
          </Link>
          <Link to={ROUTES.ADMIN_PROTOCOLS} key="Admin Protocols">
            <Button style={{ color: 'white' }}>
              <AssignmentIcon /> Administracija
            </Button>
          </Link>
          <Divider variant="middle" />
          <Divider variant="middle" />
          {/*
          <Button
            onClick={() => {
              console.log(props.fullState.patients.saveQueue);
              console.log(props.fullState.protocols.saveQueue);
            }}
          >
            ADMIN
          </Button>*/}
          <Button
            onClick={() => {
              props.showSnack('warning', 'SAVING IN PROGRESS...');

              /*//Remove any "ghost" patientAnswers that might exist
              _.forEach(props.fullState.protocols.protocols, protocol => {
                _.forEach(protocol.pages, page => {
                  _.forEach(page.questions, question => {
                    if (question.patientAnswer !== undefined) {
                      delete question.patientAnswer;
                      props.showSnack('error', 'ERROR! CHECK CONSOLE!');
                    }
                  });
                });
              });*/

              let updates = {};
              /* FULL STATE SAVE (LOTS OF DATA)
              updates['/protocols'] = props.fullState.protocols;
              updates['/patients'] = props.fullState.patients;
              */

              _.forIn(props.fullState.patients.saveQueue, (value, key) => {
                if (value === 'update') updates['/patients/patients/' + key] = props.fullState.patients.patients[key];
                else updates['/patients/patients/' + key] = null; //delete patient
              });

              _.forIn(props.fullState.protocols.saveQueue, (value, key) => {
                if (value === 'update') updates['/protocols/protocols/' + key] = props.fullState.protocols.protocols[key];
                else updates['/protocols/protocols/' + key] = null; //delete protocol
              });

              props.firebase.db.ref().update(updates, error => {
                if (error) {
                  // The write failed...
                  props.showSnack('error', 'SAVING FAILED!!!');
                } else {
                  // Data saved successfully!
                  props.clearSaveQueue();
                  props.showSnack('success', 'SAVE COMPLETE!');
                  
                  /** TODO: Rewrite
                   const id = moment().format('YYYY-MM-DD HH');
                  const backupState = { patients: props.fullState.patients, protocols: props.fullState.protocols };
                  props.firebase.backup(id).set(backupState, () => {
                    props.firebase.setBackupsList(id).set(id, () => {
                      props.showSnack('success', 'BACKUP COMPLETE!');
                      //DELETE OLD BACKUPS
                      props.firebase.backupsList().once('value', snapshot => {
                        const output = [];
                        snapshot.forEach(childSnapshot => {
                          output.push({ id: childSnapshot.key, value: childSnapshot.val() });
                        });
                        //if more than 10 backups, delete the oldest one
                        if (output.length > 10) props.firebase.deleteBackup(output[0].id, output[0].value);
                      });
                    });
                  });
                   */
                }
              });
            }}
          >
            <SaveIcon style={{ color: 'white' }} />
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateToProps = state => ({
  currentPatientName: state.patients.currentPatient !== '' ? state.patients.patients[state.patients.currentPatient].name : 'Current Patient',
  userRole: state.user.role,
  fullState: state,
});

const mapDispatchToProps = dispatch => ({
  showSnack: (severity, message) => dispatch({ type: AppActions.SET_SNACK, isOpen: true, severity, message }),
  clearSaveQueue: () => {
    dispatch({ type: ProtocolActions.CLEAR_SAVE_QUEUE });
    dispatch({ type: PatientActions.CLEAR_SAVE_QUEUE });
  },
});

export default compose(withFirebase, withRouter, connect(mapStateToProps, mapDispatchToProps))(Navigation);
