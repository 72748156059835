import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import _ from 'lodash';
import { green } from '@material-ui/core/colors';
import { FormControl, InputLabel, MenuItem, Select, Paper, Button, Typography, Grid } from '@material-ui/core';
import * as ProtocolActions from './ProtocolActions';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(3),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

function DuplicateModal(props) {
  const classes = useStyles();
  const [targetProtocol, setTargetProtocol] = React.useState(props.currentDuplicateProtocol);
  const [targetPage, setTargetPage] = React.useState(props.currentDuplicatePage);

  const protocolItems = () => {
    let protocols = [];
    _.forIn(props.protocols.protocols, function(value, key) {
      protocols.push({ id: value.id, name: value.name });
    });
    protocols = _.sortBy(protocols, 'name');

    const output = [];
    _.forEach(protocols, value =>
      output.push(
        <MenuItem key={value.id} value={value.id}>
          {value.name}
        </MenuItem>,
      ),
    );
    return output;
  };

  const pageItems = () => {
    const output = [];
    for (let index = 0; index < props.protocols.protocols[targetProtocol].pages.length; index++) {
      output.push(
        <MenuItem key={`protocol-page-duplicate${index}`} value={index}>
          {props.protocols.protocols[targetProtocol].pages[index].name}
        </MenuItem>,
      );
    }
    return output;
  };

  return (
    <Modal className={classes.modal} open={props.isOpen} onClose={() => props.setModal(false)} BackdropComponent={Backdrop}>
      <Paper className={classes.paper}>
        <Grid container direction="column" justify="center" alignItems="stretch">
          <Typography variant="h5">Choose a protocol to copy this question to</Typography>
          <FormControl variant="outlined">
            <InputLabel>Target Protocol:</InputLabel>
            <Select
              value={targetProtocol}
              onChange={event => {
                setTargetPage(0);
                setTargetProtocol(event.target.value);
              }}
            >
              {protocolItems()}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel>Target Page:</InputLabel>
            <Select value={targetPage} onChange={event => setTargetPage(event.target.value)}>
              {pageItems()}
            </Select>
          </FormControl>
          <Button
            className={classes.fabGreen}
            onClick={() => {
              props.duplicate(targetProtocol, targetPage);
              props.setModal(false);
            }}
          >
            Duplicate
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
}

const mapStateToProps = state => ({
  protocols: state.protocols,
  currentProtocol: state.protocols.currentProtocol,
  currentPage: state.protocols.currentPage,
  currentDuplicateProtocol:
    state.protocols.currentDuplicateProtocol !== undefined ? state.protocols.currentDuplicateProtocol : state.protocols.currentProtocol,
  currentDuplicatePage: state.protocols.currentDuplicatePage !== undefined ? state.protocols.currentDuplicatePage : state.protocols.currentPage,
});

const mapDispatchToProps = dispatch => ({
  duplicate: (targetProtocol, targetPage) => dispatch({ type: ProtocolActions.DUPLICATE_QUESTION, targetProtocol, targetPage }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(DuplicateModal);
