import { Button, Grid, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import React from 'react';
import DuplicatePageModal from './DuplicatePageModal';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ReactDragListView from 'react-drag-listview';
import RUSure from '../app/RUSure';
import { Link } from 'react-router-dom';

export default function AdminProtocolPageAdmin(props) {
  const moveDown = index => {
    const prevItem = { ...props.pages[index] };
    const nextItem = { ...props.pages[index + 1] };
    prevItem.index += 1;
    nextItem.index -= 1;
    props.pages[index + 1] = prevItem;
    props.pages[index] = nextItem;
    props.setProperty('pages', props.pages);
    props.selectPage(index + 1);
  };
  const moveUp = index => {
    const prevItem = { ...props.pages[index] };
    const nextItem = { ...props.pages[index - 1] };
    prevItem.index -= 1;
    nextItem.index += 1;
    props.pages[index - 1] = prevItem;
    props.pages[index] = nextItem;
    props.setProperty('pages', props.pages);
    props.selectPage(index - 1);
  };
  const changeLabel = (index, value) => {
    props.pages[index].name = value;
    props.setProperty('pages', props.pages);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      console.log(fromIndex, toIndex);

      const data = props.pages;
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.index = index;
      }
      props.setProperty('pages', props.pages);
    },
    nodeSelector: 'li',
    handleSelector: 'a',
  };

  const pages = () => {
    const output = [];
    for (let index = 0; index < props.pages.length; index++) {
      const page = props.pages[index];

      if (page.index === props.currentPage)
        output.push(
          <li key={index} style={{ listStyleType: 'none' }}>
            <Grid item key={'adminRow' + props.index + ':' + index}>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <a href="#">
                  <DragIndicatorIcon />
                </a>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter page name"
                    variant="outlined"
                    value={page.name}
                    onChange={event => changeLabel(page.index, event.target.value)}
                  />
                </Grid>
                <Grid item>
                  <Toolbar
                    pagesLentgh={props.pages.length}
                    answer={page}
                    delete={props.deletePage}
                    moveDown={moveDown}
                    moveUp={moveUp}
                    duplicatePage={setIsModalOpen}
                  />
                </Grid>
              </Grid>
            </Grid>
          </li>,
        );
      else
        output.push(
          <li key={index} style={{ listStyleType: 'none' }}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <a href="#">
                <DragIndicatorIcon />
              </a>
              <Grid item xs={11}>
                <Link to="#" id="outlined-required" variant="outlined" onClick={() => props.selectPage(page.index)}>
                  {page.name}
                </Link>
              </Grid>
            </Grid>
          </li>,
        );
    }
    return output;
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <Grid container spacing={1} direction="column" justify="flex-start" alignItems="stretch">
      <ReactDragListView {...dragProps}>{pages()}</ReactDragListView>
      <Grid item>
        <Button onClick={() => props.addPage()}>
          <AddIcon /> Add Page
        </Button>
      </Grid>
      <DuplicatePageModal isOpen={isModalOpen} setModal={setIsModalOpen} />
    </Grid>
  );
}

function Toolbar(props) {
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  return (
    <Grid item>
      <Button onClick={() => props.duplicatePage(true)}>
        <FilterNoneIcon />
      </Button>
      <Button disabled={props.pagesLentgh === 1} onClick={() => setIsDeleteOpen(true)}>
        <DeleteOutlineIcon />
      </Button>
      <RUSure isOpen={isDeleteOpen} setModal={setIsDeleteOpen} delete={() => props.delete(props.answer.index)} />
    </Grid>
  );
}
