import { Button, Checkbox, Grid, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ReactDragListView from 'react-drag-listview';
import React from 'react';
import InstrumentModal from './InstrumentModal';
import InstrumentTableView from '../instrumenttable/InstrumentTableView';

export default function CheckboxesAdmin(props) {
  const addAnswer = () => {
    props.answers.push({ index: props.answers.length, answer: '' });
    props.setProperty('answers', props.answers);
  };
  const deleteAnswer = index => {
    props.answers.splice(index, 1);
    for (let index = 0; index < props.answers.length; index++) {
      const element = props.answers[index];
      element.index = index;
    }
    props.setProperty('answers', props.answers);
  };

  const changeLabel = (index, value) => {
    props.answers[index].answer = value;
    props.setProperty('answers', props.answers);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      console.log(fromIndex, toIndex);

      const data = props.answers;
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.index = index;
      }
      props.setProperty('answers', props.answers);
    },
    nodeSelector: 'li',
    handleSelector: 'a',
  };

  const answers = () => {
    if (props.answers === undefined) return [];
    const output = [];
    for (let index = 0; index < props.answers.length; index++) {
      const answer = props.answers[index];
      let editorField = (
        <TextField
          fullWidth
          placeholder="Enter answer text"
          variant="outlined"
          value={answer.answer}
          onChange={event => changeLabel(answer.index, event.target.value)}
        />
      );
      if (answer.answer.table !== undefined) editorField = <InstrumentTableView {...answer.answer} answer={() => {}} />;
      output.push(
        <li key={index} style={{ listStyleType: 'none' }}>
          <Grid item key={'adminRow' + props.index + ':' + index}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <a href="#">
                <DragIndicatorIcon />
              </a>
              <Grid item xs={10}>
                {editorField}
              </Grid>
              <Grid item>
                <Button onClick={() => setOpenInstrumentModal({ isOpen: true, index: answer.index })}>
                  <AssignmentIcon />
                </Button>
                <Button onClick={() => deleteAnswer(answer.index)}>
                  <DeleteOutlineIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </li>,
      );
    }
    return output;
  };

  const [openInstrumentModal, setOpenInstrumentModal] = React.useState({ isOpen: false, index: 0 });

  return (
    <Grid container spacing={1} direction="column" justify="flex-start" alignItems="stretch">
      <InstrumentModal isOpen={openInstrumentModal} setModal={setOpenInstrumentModal} {...props} />
      <ReactDragListView {...dragProps}>{answers()}</ReactDragListView>
      <Grid item>
        <Button onClick={() => addAnswer()}>
          <AddIcon />
        </Button>
      </Grid>
    </Grid>
  );
}
