import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from '../session/withAuthorization';
import { withFirebase } from '../firebase';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {
  Grid,
  Container,
  IconButton,
  Fab,
  Toolbar,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import TuneIcon from '@material-ui/icons/Tune';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Navigation from '../navigation';
import * as PatientActions from './PatientActions';
import * as AppActions from '../app/AppActions';
import _ from 'lodash';
import moment from 'moment';
import RUSure from '../app/RUSure';

const Patients = props => {

  useEffect(() => {
    const onError = err => {
      console.log('Writing to Firebase error: ', err);
      //props.showSnack('error', err);
    };
    
    props.firebase.lastPatientsUpdate().on('value', snapshot => {
      //if local date is earlier than server date, download new state
      console.log('getting PATIENTS from FB', props.lastPatientsUpdate, snapshot.val(), props.lastPatientsUpdate < snapshot.val());
      if (props.user.isLoggedIn && props.lastPatientsUpdate < snapshot.val()) {
        props.firebase
        .patients()
        .once('value')
        .then(snapshot => {
          console.log('received PATIENTS');
          //props.showSnack('success', 'Loaded PATIENTS');
          props.loadPatients(snapshot.val());
        })
        .catch(onError);
      }
    });
    // Specify how to clean up after this effect:
    return function cleanup() {
      console.log('stop listening for updates on Firebase');
      props.firebase.lastPatientsUpdate().off();
    };
  }, [props.user.isLoggedIn, props.lastPatientsUpdate]);

  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');
  const [deletePatientId, setDeleteProtocol] = React.useState('');
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  const onAddPatient = () => {
    const patients = _.orderBy(props.patients, 'protocol_number', 'desc');
    let lastProtocolNumber = patients[0].protocol_number;
    const protocolNumberParts = lastProtocolNumber.split('/', 1);
    lastProtocolNumber = parseInt(protocolNumberParts[0]) + 1;
    props.addPatient(lastProtocolNumber);
  };

  const onSelectPatient = id => {
    props.selectPatient(id);
  };

  const patients = () => {
    const output = [];
    let patients = _.orderBy(props.patients, 'protocol_number', 'desc');

    _.forIn(patients, function(value, key) {
      if (value.name.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 || value.protocol_number.indexOf(searchText) >= 0)
        output.push(
          <TableRow key={value.id + value.name}>
            <TableCell component="th" scope="row">
              <Link key={value.id} onClick={() => onSelectPatient(value.id)} to={ROUTES.PATIENT}>
                <Button color="secondary">{value.protocol_number + ': ' + value.name + ' (' + moment(value.birthdate).format('yyyy') + ')'}</Button>
              </Link>
            </TableCell>
            <TableCell align="right">
              {props.userRole === 'admin' && (
                <Button
                  onClick={() => {
                    setDeleteProtocol(value.id);
                    setIsDeleteOpen(true);
                  }}
                >
                  <DeleteOutlineIcon />
                </Button>
              )}
            </TableCell>
          </TableRow>,
        );
    });
    return output;
  };

  return (
    <Grid container direction="column" justify="flex-start" alignItems="stretch">
      <Navigation title="Pacijenti" />
      <Grid container direction="column" justify="center" alignItems="stretch" className={classes.container}>
        <Toolbar>
          <Input placeholder="Pretraga po imenu ili broju protokola" onChange={event => setSearchText(event.target.value)} className={classes.grow} />
          <Link onClick={onAddPatient} to={ROUTES.PATIENT}>
            <Button color="primary" variant="contained" className={classes.roundedButton}>
              Dodaj novog pacijenta
            </Button>
          </Link>
        </Toolbar>
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Broj protokola: Ime i prezime (Godina rođenja)</Typography>
                  </TableCell>
                  <TableCell align="right">Brisanje</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{patients()}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <RUSure isOpen={isDeleteOpen} setModal={setIsDeleteOpen} delete={() => props.delete(deletePatientId)} />
      </Grid>
    </Grid>
  );
}

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => ({
  user: state.user,
  patients: state.patients.patients,
  userRole: state.user.role,
  lastPatientsUpdate: state.patients.lastUpdate,
});

const mapDispatchToProps = dispatch => ({
  addPatient: index => dispatch({ type: PatientActions.ADD_PATIENT, index }),
  selectPatient: value => dispatch({ type: PatientActions.SELECT_PATIENT, value }),
  delete: id => dispatch({ type: PatientActions.DELETE_PATIENT, id }),
  showSnack: (severity, message) => dispatch({ type: AppActions.SET_SNACK, isOpen: true, severity, message }),
  loadPatients: value => dispatch({ type: PatientActions.LOAD_PATIENTS, value }),
});

export default compose(withFirebase, withAuthorization(condition), connect(mapStateToProps, mapDispatchToProps))(Patients);

const useStyles = makeStyles(theme => ({
  roundedButton: {
    borderRadius: 10,
  },
  container: {
    padding: theme.spacing(3),
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));
