export const ADD_PROTOCOL = 'Protocol/ADD_PROTOCOL';
export const SET_PROPERTY = 'Protocol/SET_PROPERTY';
export const SELECT_PROTOCOL = 'Protocol/SELECT_PROTOCOL';
export const DELETE_PROTOCOL = 'Protocol/DELETE_PROTOCOL';

export const SELECT_PROTOCOL_FILTER = 'Protocol/SELECT_PROTOCOL_FILTER';

export const ADD_PROTOCOL_PAGE = 'Protocol/ADD_PROTOCOL_PAGE';
export const DUPLICATE_PROTOCOL_PAGE = 'Protocol/DUPLICATE_PROTOCOL_PAGE';
export const SELECT_PROTOCOL_PAGE = 'Protocol/SELECT_PROTOCOL_PAGE';
export const DELETE_PROTOCOL_PAGE = 'Protocol/DELETE_PROTOCOL_PAGE';

export const ADD_PROTOCOL_QUESTION = 'Protocol/ADD_PROTOCOL_QUESTION';
export const SELECT_PROTOCOL_QUESTION = 'Protocol/SELECT_PROTOCOL_QUESTION';
export const SET_QUESTION_PROPERTY = 'Protocol/SET_QUESTION_PROPERTY';
export const MOVE_QUESTION_UP = 'Protocol/MOVE_QUESTION_UP';
export const MOVE_QUESTION_DOWN = 'Protocol/MOVE_QUESTION_DOWN';
export const MOVE_QUESTION_TO_INDEX = 'Protocol/MOVE_QUESTION_TO_INDEX';
export const DUPLICATE_QUESTION = 'Protocol/DUPLICATE_QUESTION';
export const DELETE_QUESTION = 'Protocol/DELETE_QUESTION';
export const LOAD_PROTOCOLS = 'Protocol/LOAD_PROTOCOLS';
export const CLEAR_SAVE_QUEUE = 'Protocol/CLEAR_SAVE_QUEUE';
