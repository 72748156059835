export const ADD_PATIENT = 'Patient/ADD_PATIENT';
export const SELECT_PATIENT = 'Patient/SELECT_PATIENT';
export const DELETE_PATIENT = 'Patient/DELETE_PATIENT';
export const SET_PROPERTY = 'Patient/SET_PROPERTY';
export const ADD_PROTOCOL = 'Patient/ADD_PROTOCOL';
export const DUPLICATE_PROTOCOL = 'Patient/DUPLICATE_PROTOCOL';
export const DELETE_PROTOCOL = 'Patient/DELETE_PROTOCOL';
export const SELECT_PROTOCOL = 'Patient/SELECT_PROTOCOL';
export const SET_PROTOCOL = 'Patient/SET_PROTOCOL';
export const SET_PROTOCOL_PROPERTY = 'Patient/SET_PROTOCOL_PROPERTY';
export const SELECT_PROTOCOL_PAGE = 'Patient/SELECT_PROTOCOL_PAGE';
export const ANSWER_QUESTION = 'Patient/ANSWER_QUESTION';
export const REMOVE_QUESTION = 'Patient/REMOVE_QUESTION';
export const SELECT_QUESTION = 'Patient/SELECT_QUESTION';
export const SET_QUESTION_PROPERTY = 'Patient/SET_QUESTION_PROPERTY';
export const LOAD_PATIENTS = 'Patient/LOAD_PATIENTS';
export const CLEAR_SAVE_QUEUE = 'Patient/CLEAR_SAVE_QUEUE';
