import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import SignUpPage from '../session/SignUp';
import SignInPage from '../session/SignIn';
import PasswordForgetPage from '../session/PasswordForget';
import Patients from '../patient/Patients';
import Patient from '../patient/Patient';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../firebase';
import AdminProtocols from '../protocol/AdminProtocols';
import AdminProtocol from '../protocol/AdminProtocol';
import PatientProtocol from '../patient/PatientProtocol';
import NewPatientProtocol from '../patient/NewPatientProtocol';
import FinishPatientProtocol from '../patient/FinishPatientProtocol';
import AdminProtocolPage from '../protocol/AdminProtocolPage';
import * as PatientsActions from '../patient/PatientActions';
import * as ProtocolActions from '../protocol/ProtocolActions';
import * as AppActions from '../app/AppActions';
import ErrorHandler from '../app/ErrorHandler';
import ScrollToTop from './ScrollToTop';
import PrintPatientProtocol from '../patient/PrintPatientProtocol';
import Backups from '../backup/Backups';

const AppBasic = props => {
  

  return (
    <ErrorHandler>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path={ROUTES.LANDING} component={Patients} />
          <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route path={ROUTES.PATIENTS} component={Patients} />
          <Route path={ROUTES.PATIENT} component={Patient} />
          <Route path={ROUTES.NEW_PATIENT_PROTOCOL} component={NewPatientProtocol} />
          <Route path={ROUTES.FINISH_PATIENT_PROTOCOL} component={FinishPatientProtocol} />
          <Route path={ROUTES.PRINT_PATIENT_PROTOCOL} component={PrintPatientProtocol} />
          <Route path={ROUTES.PATIENT_PROTOCOL} component={PatientProtocol} />
          <Route path={ROUTES.ADMIN_PROTOCOLS} component={AdminProtocols} />
          <Route path={ROUTES.ADMIN_PROTOCOL} component={AdminProtocol} />
          <Route path={ROUTES.ADMIN_PROTOCOL_PAGE} component={AdminProtocolPage} />
          <Route path={ROUTES.BACKUPS} component={Backups} />
        </Switch>
      </Router>
    </ErrorHandler>
  );
};

const mapStateToProps = state => ({
  lastPatientsUpdate: state.patients.lastUpdate,
  lastProtocolsUpdate: state.protocols.lastUpdate,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  showSnack: (severity, message) => dispatch({ type: AppActions.SET_SNACK, isOpen: true, severity, message }),
  loadPatients: value => dispatch({ type: PatientsActions.LOAD_PATIENTS, value }),
  loadProtocols: value => dispatch({ type: ProtocolActions.LOAD_PROTOCOLS, value }),
});

const App = compose(withFirebase, connect(mapStateToProps, mapDispatchToProps))(AppBasic);

export default App;
