import { TextField } from '@material-ui/core';
import React from 'react';
import ProtocolOverviewItem from '../../../patient/ProtocolOverviewItem';

export default function LongAnswerView(props) {
  const patientAnswer = props.patientAnswer === undefined ? '' : props.patientAnswer;
  return (
    <TextField
      variant="outlined"
      fullWidth
      multiline
      onChange={event => props.answer({ index: props.index, answer: event.target.value })}
      value={patientAnswer}
    />
  );
}

export function LongAnswerDiagnosis(props) {
  if (props.patientAnswer !== undefined && props.patientAnswer !== '')
    return <ProtocolOverviewItem question={props.question} answer={props.patientAnswer} />;
  else return null;
}
