//export const SITE_URL = 'https://diag.nika.rs/';
export const SITE_URL = 'https://diag.nika.rs/';

export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PATIENTS = '/patients';
export const PATIENT = '/patient';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_PROTOCOLS = '/admin-protocols';
export const ADMIN_PROTOCOL = '/admin-protocol';
export const ADMIN_PROTOCOL_PAGE = '/admin-protocol-page';
export const PATIENT_PROTOCOL = '/patient-protocol';
export const NEW_PATIENT_PROTOCOL = '/new-patient-protocol';
export const FINISH_PATIENT_PROTOCOL = '/finish-patient-protocol';
export const PRINT_PATIENT_PROTOCOL = '/print-patient-protocol';
export const BACKUPS = '/backups';
