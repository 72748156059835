import {
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Navigation from '../navigation';
import withAuthorization from '../session/withAuthorization';
import * as PatientActions from './PatientActions';
import ProtocolPages from './PatientProtocolNavigation';
import * as Components from '../protocol/components/Components';
import { Redirect, Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import moment from 'moment';

function PrintPatientProtocol(props) {
  if (props.protocol === undefined) return <Redirect to={ROUTES.PATIENTS} />;

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('PRINT!');
      window.print();
    }, 3000);
    return () => clearTimeout(timer);
  });

  const classes = useStyles();

  const diagnosisItems = () => {
    const output = [];
    for (let index = 0; index < props.protocol.pages.length; index++) {
      const page = props.protocol.pages[index];

      if (page.questions !== undefined)
        for (let qIndex = 0; qIndex < page.questions.length; qIndex++) {
          const question = page.questions[qIndex];
          const item = Components.getDiagnosis(question);
          if (item !== null)
            output.push(
              <TableRow>
                <TableCell>{item}</TableCell>
              </TableRow>,
            );
        }
    }
    return output;
  };

  const header =
    props.protocol.type === 'exam' ? (
      <Grid item>
        <Link className={classes.print} to={ROUTES.PATIENT}>
          <Button>
            <div style={{ width: '800px', height: '250px' }} />
          </Button>
        </Link>
      </Grid>
    ) : null;

  return (
    <Grid container direction="column" justify="flex-start" alignItems="center">
      {header}
      <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch" className={classes.container}>
        <Grid item>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Broj protokola: {props.patient.protocol_number}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Prezime i ime:</b> {props.patient.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Pol i datum rođenja:</b> {props.patient.gender + ' | ' + moment(props.patient.birthdate).format('DD/MM/yyyy')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Adresa:</b> {props.patient.address + ', ' + props.patient.city + ', ' + props.patient.zip}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Kontakt:</b> {props.patient.phone + ' | ' + props.patient.email}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {!_.isEmpty(props.protocol.anamnesis) && (
          <Grid item>
            <Paper elevation={3} className={classes.container}>
              <Typography variant="h5">Anamnesis</Typography>
              <TextField fullWidth multiline value={props.protocol.anamnesis} />
            </Paper>
          </Grid>
        )}
        <Grid item>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {props.protocol.type === 'exam' && <Typography variant="h5">TERAPEUTSKA PROCENA - FUNKCIONALNI STATUS</Typography>}
                    {props.protocol.type !== 'exam' && <Typography variant="h5">Terapija</Typography>}
                  </TableCell>
                </TableRow>
                {diagnosisItems()}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {props.protocol.type === 'exam' && !_.isEmpty(props.protocol.diagnosis) && (
          <Grid item>
            <Paper elevation={3} className={classes.container}>
              <Typography variant="h5">Dijagnoza</Typography>
              <TextField fullWidth multiline value={props.protocol.diagnosis + `\n\n`} />
            </Paper>
          </Grid>
        )}
        {!_.isEmpty(props.protocol.conclusion) && (
          <Grid item>
            <Paper elevation={3} className={classes.container}>
              <Typography variant="h5">Zaključak</Typography>
              <TextField fullWidth multiline value={props.protocol.conclusion + `\n\n`} />
            </Paper>
          </Grid>
        )}
        {props.protocol.type === 'exam' && !_.isEmpty(props.protocol.therapy) && (
          <Grid item>
            <Paper elevation={3} className={classes.container}>
              <Typography variant="h5">Terapija</Typography>
              <TextField fullWidth multiline value={props.protocol.therapy + `\n\n`} />
            </Paper>
          </Grid>
        )}
        <Grid item>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Grid item direction="column" justify="flex-start" alignItems="flex-start">
              <Typography variant="body1">Beograd,</Typography>
              <Typography variant="body1" className={classes.paper}>
                {moment().format('DD. MM. YYYY')}
              </Typography>
            </Grid>
            <Grid item direction="column" justify="flex-end" alignItems="flex-end">
              <Typography variant="body1" className={classes.paper}>
                Žarko Popović
              </Typography>
              <Typography variant="body2">viši fizioterapeut</Typography>
              <Typography variant="body2">defektolog - somatoped</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => {
  if (state.patients.currentPatient === '' || state.patients.currentProtocol === '')
    return { currState: state, protocol: undefined, patient: { name: '' } };

  return {
    protocol: state.patients.patients[state.patients.currentPatient].protocols[state.patients.currentProtocol],
    currState: state,
    patient: state.patients.patients[state.patients.currentPatient],
    currentProtocol: state.patients.currentProtocol,
  };
};

const mapDispatchToProps = dispatch => ({
  setProtocol: value => dispatch({ type: PatientActions.SET_PROTOCOL, value }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(PrintPatientProtocol);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
  },
  buttonContainer: {
    paddingLeft: theme.spacing(3),
  },
  outline: {
    borderStyle: 'solid',
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
    padding: theme.spacing(3),
  },
  formControl: {
    minWidth: 250,
  },
}));
