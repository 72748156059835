import { Button, Grid, Paper, Typography, Box } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { red } from '@material-ui/core/colors';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
  },
  paper: {
    padding: theme.spacing(5),
  },
  divider: {
    height: 20,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: red[900],
    '&:hover': {
      backgroundColor: red[900],
    },
  },
}));

export default function RUSure(props) {
  const classes = useStyles();
  const buttonLabel = props.buttonLabel !== undefined ? props.buttonLabel : 'OBRIŠI ZAUVEK';

  return (
    <Modal className={classes.modal} open={props.isOpen} onClose={() => props.setModal(false)} BackdropComponent={Backdrop}>
      <Paper className={classes.paper}>
        <Grid container direction="column" justify="center" alignItems="stretch">
          <Typography variant="h5">Da li ste sigurni?</Typography>
          <Typography variant="body1">Ova operacija je nepovratna!</Typography>
          <Box className={classes.divider} />
          <Box className={classes.divider} />
          <Button
            className={classes.fabGreen}
            onClick={() => {
              props.delete();
              props.setModal(false);
            }}
          >
            {buttonLabel}
          </Button>
          <Box className={classes.divider} />
          <Button
            onClick={() => {
              props.setModal(false);
            }}
          >
            Odustani
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
}
