import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../firebase';
import * as ROUTES from '../../constants/routes';
import { Grid, Button, Typography, Box, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import * as GlobalActions from '../../config/globalActions';
import * as ProtocolActions from '../protocol/ProtocolActions';

const SignInPage = () => (
  <Grid container direction="column" justify="center" alignItems="center" style={{ backgroundColor: 'white', height: '100vh' }}>
    <img src="https://www.nika.rs/wp-content/uploads/2021/03/logo-big-200x176-1-17-godina-sa-vama.jpg" />
    <Box style={{ height: '60px' }} />
    <Typography variant="h4">Dobrodošli u PHYSIO PRO</Typography>
    <Typography>Molimo Vas da unesete Vašu e-mail adresu i šifru:</Typography>
    <Box style={{ height: '60px' }} />
    <SignInForm />
  </Grid>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onError = err => {
    console.log('Writing to Firebase error: ', err);
    //props.showSnack('error', err);
  };

  onSubmit = event => {
    const { email, password } = this.state;
    console.log('onSubmit');
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        console.log('onFBSignIn');
        //listen for changes in protocols
        this.props.firebase.lastProtocolsUpdate().on('value', snapshot => {
          //if local date is earlier than server date, download new state
          console.log('getting PROTOCOLS from FB', this.props.lastProtocolsUpdate, snapshot.val(), this.props.lastProtocolsUpdate < snapshot.val());
          if (this.props.lastProtocolsUpdate < snapshot.val()) {
            this.props.firebase
              .protocols()
              .once('value')
              .then(snapshot => {
                console.log('received PROTOCOLS');
                //props.showSnack('success', 'Loaded PROTOCOLS');
                this.props.loadProtocols(snapshot.val());
              })
              .catch(this.onError);
          }
        });
        this.props.history.push(ROUTES.LANDING);
        
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  componentWillUnmount() {
    //this.props.firebase.allData().off();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <div>
        <TextField name="email" variant="outlined" onBlur={this.onChange} defaultValue={email} placeholder="E-mail" />
        <br />
        <br />
        <TextField name="password" variant="outlined" onBlur={this.onChange} defaultValue={password} placeholder="Šifra" type="password" />
        <br />
        <br />
        <Button color="secondary" variant="contained" disabled={isInvalid} onClick={this.onSubmit}>
          Nastavi
        </Button>

        {error && <p>{error.message}</p>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lastProtocolsUpdate: state.protocols.lastUpdate,
});

const mapDispatchToProps = dispatch => ({
  loadState: value => dispatch({ type: GlobalActions.LOAD_STATE_ACTION, value }),
  loadProtocols: value => dispatch({ type: ProtocolActions.LOAD_PROTOCOLS, value }),
});

const SignInForm = compose(withRouter, withFirebase, connect(mapStateToProps, mapDispatchToProps))(SignInFormBase);

export default SignInPage;

export { SignInForm };
