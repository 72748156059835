import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Paper,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Navigation from '../navigation';
import withAuthorization from '../session/withAuthorization';
import * as PatientActions from './PatientActions';
import ProtocolPages from './PatientProtocolNavigation';
import CheckboxTree from './CheckTree/CheckboxTree';
import { green } from '@material-ui/core/colors';
import * as ROUTES from '../../constants/routes';
import FileUpload from './FileUpload';
import { Redirect } from 'react-router-dom';
import RUSure from '../app/RUSure';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ReactDragListView from 'react-drag-listview';
import { typeNames } from '../../constants/maps';

function NewPatientProtocol(props) {
  if (props.protocol === undefined) return <Redirect to={ROUTES.PATIENTS} />;

  const classes = useStyles();
  const protocol = props.protocol;
  const setProtocol = props.setProtocol;
  const [checked, setChecked] = React.useState(protocol.checkedPages);
  const [expanded, setExpanded] = React.useState([]);
  const [programType, setProgramType] = React.useState(protocol.type);

  let protocols = [];
  _.forIn(props.protocols.protocols, function(value, key) {
    if (value.published && value.type === programType) protocols.push({ id: value.id, name: value.name, pages: value.pages });
  });
  protocols = _.sortBy(protocols, 'name');
    
  if (expanded.length === 0) {
    let firstExpanded = [];
    const checkedObject = {};
    _.forEach(protocol.checkedPages, checkedPage => {
      const ids = checkedPage.split('@');
      checkedObject[ids[0]] = true;
    });
    _.forIn(checkedObject, function(value, key) {
      firstExpanded.push(key);
    });

    if (firstExpanded.length === 0) {
      firstExpanded = [];
      let isFirst = true;
      _.forEach(protocols, value => {
        if (isFirst) {
          firstExpanded.push(value.id);
          isFirst = false;
          return false;
        }
      });
    }
    setExpanded(firstExpanded);
  }

  const nodes = () => {
    const output = [];
    _.forEach(protocols, value => {
      let protocol = { value: value.id, label: value.name, children: [] };
      _.forEach(value.pages, page => {
        protocol.children.push({ value: value.id + '@' + page.index, label: page.name });
      });
      output.push(protocol);
    });
    return output;
  };

  const handleCheckedChange = checked => {
    let protocolPages = [];
    let newPages = [];

    _.forEach(checked, item => {
      const ids = item.split('@');
      let alreadyInProtocol = false;
      _.forEach(protocol.pages, page => {
        if (page.id === item) {
          alreadyInProtocol = true;
          protocolPages.push(page);
          return false;
        }
      });
      if (!alreadyInProtocol) {
        newPages.push(ids);
      }
    });
    _.forEach(newPages, ids => {
      const newPage = _.cloneDeep(props.protocols.protocols[ids[0]].pages[ids[1]]);
      newPage.id = ids[0] + '@' + ids[1];
      newPage.index = protocolPages.length;
      protocolPages.push(newPage);
    });

    protocol.pages = protocolPages;
    protocol.checkedPages = checked;
    setProtocol(_.cloneDeep(protocol));

    setChecked(checked);
  };

  const records = () => {
    const output = [];

    let protocols = _.orderBy(protocol.medicalRecords, 'date', 'desc');

    _.forIn(protocols, function(value, key) {
      let files = [];
      _.forEach(value.files, file => {
        files.push(
          <a target="_blank" href={ROUTES.SITE_URL + '/uploads/' + protocol.id + '/' + file}>
            {file + ' | '}
          </a>,
        );
      });

      output.push(
        <TableRow key={value.id}>
          <TableCell align="left">{moment(value.date).format('DD/MM/YYYY')}</TableCell>
          <TableCell component="th" scope="row">
            <Typography>{value.institution}</Typography>
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography>{value.diagnosis}</Typography>
          </TableCell>
          <TableCell align="right">{files}</TableCell>
        </TableRow>,
      );
    });
    return output;
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      console.log(fromIndex, toIndex);

      const data = protocol.pages;
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.index = index;
      }
      setProtocol(_.cloneDeep(protocol));
    },
    nodeSelector: 'li',
    handleSelector: 'a',
  };

  const pages = () => {
    const output = [];
    for (let index = 0; index < protocol.pages.length; index++) {
      const page = protocol.pages[index];
      output.push(
        <li key={index} style={{ listStyleType: 'none' }}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <a href="#">
              <DragIndicatorIcon />
            </a>
            <Grid item xs={11}>
              <Typography>{page.name}</Typography>
            </Grid>
          </Grid>
        </li>,
      );
    }
    return output;
  };

  const setProgramTypeBlurHandler = (type) => {
    
    setProgramType(type);

    
  }

  return (
    <Grid container direction="column" justify="flex-start" alignItems="stretch">
      <Navigation title={props.patient.name + ' : ' + typeNames[props.protocol.type] + ' ' + props.protocol.protocol_number} />
      <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch" className={classes.container}>
        <Grid item>
          <Typography variant="h4">Program posete</Typography>
        </Grid>
        <Grid item>
          <Paper elevation={3} className={classes.container}>
            <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
              <Grid item>
                <TextField
                  label="Redni broj"
                  variant="outlined"
                  defaultValue={protocol.protocol_number}
                  onBlur={event => setProtocol({ ...protocol, protocol_number: event.target.value })}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="datetime-local"
                  label="Datum posete"
                  type="datetime-local"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={protocol.date}
                  onBlur={date => setProtocol({ ...protocol, date: date.nativeEvent.target.value })}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3} className={classes.container}>
            <TextField
              required
              id="outlined-required"
              label={protocol.type === 'exam' ? 'Anamnesis' : 'Napomene'}
              variant="outlined"
              fullWidth
              multiline
              defaultValue={protocol.anamnesis}
              onBlur={event => setProtocol({ ...protocol, anamnesis: event.target.value })}
            />
          </Paper>
        </Grid>
        {protocol.type === 'exam' && (
          <Grid item>
            <Paper elevation={3} className={classes.container}>
              <Typography variant="h6">Medicinska dokumentacija</Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Datum</TableCell>
                      <TableCell>Institucija</TableCell>
                      <TableCell>Diagnosis</TableCell>
                      <TableCell align="right">Datoteke</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{records()}</TableBody>
                </Table>
              </TableContainer>
              <Typography variant="h6"> </Typography>
              <Typography variant="h6">Dodaj novi dokument</Typography>
              <FileUpload />
            </Paper>
          </Grid>
        )}
        <Grid item>
          <Paper elevation={3} className={classes.container}>
            <Grid container spacing={3} direction="column" justify="space-between" alignItems="stretch">
              <Grid item>
                <Typography variant="h6">Izaberi programe za ovu posetu</Typography>
                <Select value={programType} onChange={event => setProgramTypeBlurHandler(event.target.value)}>
                      <MenuItem value="exam">Exam</MenuItem>
                      <MenuItem value="therapy">Physical Therapy</MenuItem>
                      <MenuItem value="kinesiotherapy">Kinesiotherapy</MenuItem>
                      <MenuItem value="grouptherapy">Group Exercises</MenuItem>
                    </Select>
                <CheckboxTree
                  iconsClass="fa5"
                  onlyLeafCheckboxes
                  nodes={nodes()}
                  checked={checked}
                  expanded={expanded}
                  onCheck={handleCheckedChange}
                  onExpand={expanded => setExpanded(expanded)}
                />
              </Grid>
              <Grid item>
                <Typography variant="h6">Organizuj redosled stranica</Typography>
                <ReactDragListView {...dragProps}>{pages()}</ReactDragListView>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              color="primary"
              variant="contained"
              className={classes.roundedButton}
              onClick={e => {
                props.selectPage({ protocolPage: 0, examPage: 1 });
                props.history.push(ROUTES.PATIENT_PROTOCOL);
              }}
            >
              Započni posetu
            </Button>
            <Box />
            <Button
              color="secondary"
              onClick={e => {
                props.selectPage({ protocolPage: '', examPage: protocol.pages.length + 1 });
                props.history.push(ROUTES.FINISH_PATIENT_PROTOCOL);
              }}
            >
              Pogledaj zaključak posete
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => ({
  protocols: state.protocols,
  protocol: state.patients.patients[state.patients.currentPatient].protocols[state.patients.currentProtocol],
  patient: state.patients.patients[state.patients.currentPatient],
});

const mapDispatchToProps = dispatch => ({
  setProtocol: value => dispatch({ type: PatientActions.SET_PROTOCOL, value }),
  selectPage: value => dispatch({ type: PatientActions.SELECT_PROTOCOL_PAGE, value }),
});

export default compose(withAuthorization(condition), connect(mapStateToProps, mapDispatchToProps))(NewPatientProtocol);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
  },
  buttonContainer: {
    paddingLeft: theme.spacing(3),
  },
  outline: {
    borderStyle: 'solid',
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
    padding: theme.spacing(3),
  },
  formControl: {
    minWidth: 250,
  },
  pagesContrainer: {
    height: 216,
    flexGrow: 1,
    maxWidth: 400,
  },
  roundedButton: {
    borderRadius: 10,
  },
}));
