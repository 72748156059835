import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import localforage from 'localforage';
import firebase from 'firebase/app';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import { USER_LOGOUT_ACTION, LOAD_STATE_ACTION } from './globalActions';
import SetTransform from './transforms';

import UserReducer from '../features/user/UserReducer';
import PatientReducer from '../features/patient/PatientReducer';
import ProtocolReducer from '../features/protocol/ProtocolReducer';
import AppReducer from '../features/app/AppReducer';

const appReducer = combineReducers({
  user: UserReducer,
  patients: PatientReducer,
  protocols: ProtocolReducer,
  app: AppReducer,
});

const persistedReducers = ['user', 'patients', 'protocols', 'app'];

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: persistedReducers,
  transforms: [SetTransform],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = (state, action) => {
  if (action.type === LOAD_STATE_ACTION) {
    return appReducer(action.value, action);
  }

  if (action.type === USER_LOGOUT_ACTION) {
    localforage.removeItem(`persist:root`);
    firebase.auth().signOut();

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const pReducer = persistReducer(persistConfig, rootReducer);

export default intialState => {
  const store = createStore(pReducer, intialState, applyMiddleware(thunkMiddleware, logger));
  const persistor = persistStore(store);
  // persistor.purge(); // for debug purposes - clear persisted store
  return { store, persistor };
};
