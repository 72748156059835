import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as AppActions from './AppActions';

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorOccurred: false, message: '' };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
    this.props.showSnack(error.toString() + '<br/>' + info.componentStack);
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  showSnack: message => dispatch({ type: AppActions.SET_SNACK, isOpen: true, severity: 'error', message }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ErrorHandler);
