import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';

function InstrumentTableView(props) {
  console.log(props);
  let propsTable = props.table;
  let propsTableData = props.tableData;
  let propsQuestion = props.question;

  if (props.path !== undefined) {
    const ids = props.path.split('@');
    const instrument = props.protocols[ids[0]].pages[ids[1]].questions[ids[2]];
    const newInstrument = _.cloneDeep(instrument);
    const newRow = _.cloneDeep(instrument.table.rows[ids[3]]);
    newRow.index = 0;
    newInstrument.table.rows = [newRow];
    newInstrument.tableData = { r0: instrument.tableData['r' + ids[3]] };
    newInstrument.path = undefined;

    propsTable = newInstrument.table;
    propsTableData = newInstrument.tableData;
    propsQuestion = newInstrument.question;
  }

  const handleChange = (value, row, column) => {
    const newPatientAnswer = propsTableData === undefined ? {} : { ...propsTableData };
    const rowIndex = 'r' + row.index;
    const columnIndex = 'c' + column.index;
    if (newPatientAnswer[rowIndex] === undefined) newPatientAnswer[rowIndex] = {};
    newPatientAnswer[rowIndex][columnIndex] = value;
    props.answer({ index: props.index, answer: newPatientAnswer });
  };

  const drawCell = (row, column) => {
    let value = '';
    if (propsTableData === undefined) value = undefined;
    if (value !== undefined) {
      value = propsTableData[row.index] === undefined || propsTableData[row.index] === null ? undefined : propsTableData[row.index][column.index];
      //FIX TO A NEW FORMAT FOR PATIENT ANSWER. IT WAS AN ARRAY AND NOW IS AN OBJECT!!!
      if (value === undefined) value = propsTableData['r' + row.index] === undefined ? '' : propsTableData['r' + row.index]['c' + column.index];
    }

    if (column.type === 'textfield')
      return (
        <TextField
          size="small"
          key={props.index + ':' + row.index + ':' + column.index + ':text'}
          variant="outlined"
          value={value}
          onChange={event => handleChange(event.target.value, row, column)}
        />
      );
    if (column.type === 'dropdown')
      return (
        <Select value={value} onChange={event => handleChange(event.target.value, row, column)}>
          <MenuItem value="0">0</MenuItem>
        </Select>
      );
    let isChecked = value == 'true' || value === true;
    return (
      <Checkbox
        key={props.index + ':' + row.index + ':' + column.index + ':check'}
        color="primary"
        checked={isChecked}
        onChange={event => handleChange(event.target.checked, row, column)}
      />
    );
  };

  const drawRows = () => {
    const rows = [];

    for (let index = 0; index < propsTable.rows.length; index++) {
      const rowData = propsTable.rows[index];
      const cells = [];
      for (let cellIndex = 0; cellIndex < propsTable.columns.length; cellIndex++) {
        const column = propsTable.columns[cellIndex];
        cells.push(
          <TableCell key={props.index + ':' + rowData.index + ':' + cellIndex + ':cell'} align="center" size="small">
            {drawCell(rowData, column)}
          </TableCell>,
        );
      }
      rows.push(
        <TableRow key={props.index + ':' + rowData.index}>
          <TableCell>{rowData.label}</TableCell>
          {cells}
        </TableRow>,
      );
    }

    return rows;
  };

  const drawHeaderCells = () => {
    const output = [];
    for (let index = 0; index < propsTable.columns.length; index++) {
      const element = propsTable.columns[index];
      output.push(
        <TableCell key={props.index + ':' + index} align="center">
          {element.label}
        </TableCell>,
      );
    }
    return output;
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 250 }}>{propsQuestion}</TableCell>
            {drawHeaderCells()}
          </TableRow>
        </TableHead>
        <TableBody>{drawRows()}</TableBody>
      </Table>
    </TableContainer>
  );
}

const mapStateToProps = state => ({
  protocols: state.protocols.protocols,
});

export default compose(connect(mapStateToProps, null))(InstrumentTableView);
