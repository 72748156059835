import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from '../session/withAuthorization';
import * as ROUTES from '../../constants/routes';
import { Grid, FormControlLabel, Radio, RadioGroup, Button, MenuItem, Select } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import * as PatientActions from './PatientActions';

function PatientProtocolNavigation(props) {
  const classes = useStyles();

  const navArray = [];
  navArray.push({ route: ROUTES.NEW_PATIENT_PROTOCOL, value: '', label: 'Program posete' });
  for (let index = 0; index < props.protocolPages.length; index++) {
    const label = index + 1 + ':' + props.protocolPages[index].name;
    navArray.push({ route: ROUTES.PATIENT_PROTOCOL, value: index, label: label });
  }
  navArray.push({ route: ROUTES.FINISH_PATIENT_PROTOCOL, value: '', label: 'Zaključak posete' });

  const pages = () => {
    const output = [];
    for (let index = 0; index < navArray.length; index++) {
      output.push(
        <MenuItem key={'::' + index} value={index}>
          {navArray[index].label}
        </MenuItem>,
      );
    }
    return output;
  };

  const onPageChange = event => {
    const nav = navArray[event.target.value];
    props.selectPage({ protocolPage: nav.value, examPage: event.target.value });
    props.history.push(nav.route);
  };

  /*
  <Grid item>
        <Button disabled={props.currentExamPage === 0} onClick={() => onPageChange({ target: { value: props.currentExamPage - 1 } })}>
          <ArrowBackIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          disabled={props.currentExamPage === navArray.length - 1}
          onClick={() => onPageChange({ target: { value: props.currentExamPage + 1 } })}
        >
          <ArrowForwardIcon />
        </Button>
      </Grid>
      */
  return (
    <Grid container spacing={3} direction="column" justify="center" alignItems="center" className={classes.container}>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          disabled={props.isDisabled || props.currentExamPage === 0}
          className={classes.roundedButton}
          onClick={() => onPageChange({ target: { value: props.currentExamPage - 1 } })}
        >
          <ArrowBackIcon /> Prethodna strana
        </Button>
        <Button />
        <Button
          color="primary"
          variant="contained"
          className={classes.roundedButton}
          disabled={props.isDisabled || props.currentExamPage === navArray.length - 1}
          onClick={() => onPageChange({ target: { value: props.currentExamPage + 1 } })}
        >
          Sledeća strana <ArrowForwardIcon />
        </Button>
      </Grid>
      <Grid item>
        <Select disabled={props.isDisabled} value={props.currentExamPage} onChange={onPageChange} className={classes.dropdown}>
          {pages()}
        </Select>
      </Grid>
    </Grid>
  );
}

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => {
  const protocol = state.patients.patients[state.patients.currentPatient].protocols[state.patients.currentProtocol];
  return {
    protocolPages: protocol.pages === undefined ? [] : protocol.pages,
    currentExamPage: parseInt(state.patients.currentExamPage),
    currentPage: parseInt(state.patients.currentPage),
  };
};

const mapDispatchToProps = dispatch => ({
  selectPage: value => dispatch({ type: PatientActions.SELECT_PROTOCOL_PAGE, value }),
});

export default compose(withAuthorization(condition), connect(mapStateToProps, mapDispatchToProps))(PatientProtocolNavigation);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
  },
  buttonContainer: {
    paddingLeft: theme.spacing(3),
  },
  outline: {
    borderStyle: 'solid',
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  dropdown: {
    fontWeight: 'bold',
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  roundedButton: {
    borderRadius: 10,
  },
}));
