import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import * as serviceWorker from './serviceWorker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import configureStore from './config/configureStore';
import App from './features/app';
import Firebase, { FirebaseContext } from './features/firebase';
import CssBaseline from '@material-ui/core/CssBaseline';
import SnackMessage from './features/app/SnackMessage';
import Footer from './features/app/Footer';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './constants/theme';

const { persistor, store } = configureStore({});

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FirebaseContext.Provider value={new Firebase()}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
          <Footer />
          <SnackMessage />
        </ThemeProvider>
      </FirebaseContext.Provider>
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
