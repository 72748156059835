import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as AppActions from './AppActions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

function SnackMessage(props) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.resetSnack();
  };

  const handleExited = () => {
    props.resetSnack();
  };

  const classes = useStyles();
  return (
    <div>
      <Snackbar
        key={props.snack.message}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={props.snack.isOpen}
        autoHideDuration={10000}
        onClose={handleClose}
        onExited={handleExited}
        action={
          <React.Fragment>
            <IconButton aria-label="close" color="inherit" className={classes.close} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert onClose={handleClose} severity={props.snack.severity}>
          {props.snack.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = state => ({
  snack: state.app.snack,
});

const mapDispatchToProps = dispatch => ({
  resetSnack: () => dispatch({ type: AppActions.SET_SNACK, isOpen: false, severity: 'error', message: '' }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SnackMessage);
