import * as Actions from './UserActions';

const defaultState = {
  version: 1,
  lastUpdate: 1546376400,
  language: 'en',
  isLoggedIn: false,
};

export default function UserReducer(state = defaultState, action) {
  switch (action.type) {
    case Actions.SET_USER:
      return { ...state, ...action.user };

    default:
      return state;
  }
}
