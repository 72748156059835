import * as Actions from './ProtocolActions';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import moment from 'moment';

const defaultState = {
  currentProtocol: '',
  currentPage: 0,
  currentQuestion: 0,
  currentProtocolFilter: 'all',
  saveToFirebase: false,
  lastUpdate: 1546376400,
  protocols: {},
  saveQueue: {},
};

const defaultProtocol = {
  name: 'Protocol Name',
  published: false,
  pages: [
    {
      name: 'Page Name',
      index: 0,
      questions: [
        {
          index: 0,
          type: 'shortanswer',
          question: 'Enter question text...',
          answers: [{ index: 0, answer: '' }],
        },
      ],
    },
  ],
};

const addToSaveQueue = (state, protocolId, action) => {
  if (state.saveQueue === undefined) state.saveQueue = {};
  state.saveQueue[protocolId] = action;
  state.lastUpdate = moment().valueOf();
  state.saveToFirebase = true;
};

export default function ProtocolReducer(state = defaultState, action) {
  switch (action.type) {
    case Actions.LOAD_PROTOCOLS: {
      return { ...state, protocols: action.value.protocols, lastUpdate: action.value.lastUpdate, saveToFirebase: false };
    }

    case Actions.ADD_PROTOCOL: {
      const newProtocol = _.cloneDeep(defaultProtocol);
      newProtocol.id = uuidv4();
      newProtocol.type = action.protocolType;

      const newState = { ...state };
      if (newState.protocols === undefined) newState.protocols = {};
      newState.protocols[newProtocol.id] = newProtocol;
      newState.currentProtocol = newProtocol.id;
      newState.currentPage = 0;

      addToSaveQueue(newState, newProtocol.id, 'update');
      return newState;
    }

    case Actions.SELECT_PROTOCOL: {
      return { ...state, currentProtocol: action.value, currentPage: 0, saveToFirebase: false };
    }

    case Actions.SELECT_PROTOCOL_FILTER: {
      return { ...state, currentProtocolFilter: action.value, saveToFirebase: false };
    }

    case Actions.SELECT_PROTOCOL_PAGE:
      return { ...state, currentPage: action.value, currentQuestion: '', saveToFirebase: false };

    case Actions.DELETE_PROTOCOL: {
      const newState = { ...state };
      delete newState.protocols[action.value];

      addToSaveQueue(newState, action.value, 'delete');
      return newState;
    }

    case Actions.SELECT_PROTOCOL_QUESTION: {
      return { ...state, currentQuestion: action.value, saveToFirebase: false };
    }

    case Actions.SET_PROPERTY: {
      state.protocols[state.currentProtocol][action.property] = action.value;

      addToSaveQueue(state, state.currentProtocol, 'update');
      return { ...state };
    }

    case Actions.ADD_PROTOCOL_PAGE: {
      const newPage = _.cloneDeep(defaultProtocol.pages[0]);
      newPage.index = state.protocols[state.currentProtocol].pages.length;
      state.protocols[state.currentProtocol].pages.push(newPage);

      addToSaveQueue(state, state.currentProtocol, 'update');
      return { ...state };
    }

    case Actions.DUPLICATE_PROTOCOL_PAGE: {
      const targetProtocol = state.protocols[action.targetProtocol];
      const currentPage = _.cloneDeep(state.protocols[state.currentProtocol].pages[state.currentPage]);
      currentPage.index = targetProtocol.pages.length;
      targetProtocol.pages.push(currentPage);

      addToSaveQueue(state, state.currentProtocol, 'update');
      return { ...state };
    }

    case Actions.ADD_PROTOCOL_QUESTION: {
      const newQuestion = _.cloneDeep(defaultProtocol.pages[0].questions[0]);
      newQuestion.index = state.protocols[state.currentProtocol].pages[state.currentPage].questions.length;
      state.protocols[state.currentProtocol].pages[state.currentPage].questions.push(newQuestion);

      addToSaveQueue(state, state.currentProtocol, 'update');
      return { ...state };
    }

    case Actions.SET_QUESTION_PROPERTY: {
      const newState = { ...state };
      newState.protocols[state.currentProtocol].pages[state.currentPage].questions[state.currentQuestion][action.property] = action.value;

      addToSaveQueue(newState, state.currentProtocol, 'update');
      return { ...newState };
    }

    case Actions.MOVE_QUESTION_TO_INDEX: {
      const page = state.protocols[state.currentProtocol].pages[state.currentPage];
      let newIndex = parseInt(action.value);
      if(newIndex > page.questions.length) newIndex = page.questions.length;
      if(newIndex < 1) newIndex = 0;
      
      page.questions.splice(newIndex, 0, page.questions.splice(state.currentQuestion, 1)[0]);

      console.log(page.questions);
      for (let index = 0; index < page.questions.length; index++) {
        const element = page.questions[index];
        element.index = index;
      }

      addToSaveQueue(state, state.currentProtocol, 'update');
      return { ...state, currentQuestion: newIndex };
    }

    case Actions.MOVE_QUESTION_UP: {
      const page = state.protocols[state.currentProtocol].pages[state.currentPage];
      page.questions[state.currentQuestion].index = state.currentQuestion - 1;
      page.questions[state.currentQuestion - 1].index = state.currentQuestion;
      const prevItem = { ...page.questions[state.currentQuestion - 1] };
      page.questions[state.currentQuestion - 1] = {
        ...page.questions[state.currentQuestion],
      };
      page.questions[state.currentQuestion] = prevItem;
      state.currentQuestion -= 1;

      addToSaveQueue(state, state.currentProtocol, 'update');
      return { ...state };
    }

    case Actions.MOVE_QUESTION_DOWN: {
      const page = state.protocols[state.currentProtocol].pages[state.currentPage];
      page.questions[state.currentQuestion].index = state.currentQuestion + 1;
      page.questions[state.currentQuestion + 1].index = state.currentQuestion;
      const prevItem = { ...page.questions[state.currentQuestion + 1] };
      page.questions[state.currentQuestion + 1] = {
        ...page.questions[state.currentQuestion],
      };
      page.questions[state.currentQuestion] = prevItem;
      state.currentQuestion += 1;

      addToSaveQueue(state, state.currentProtocol, 'update');
      return { ...state };
    }

    case Actions.DUPLICATE_QUESTION: {
      const currentQuestionData = _.cloneDeep(state.protocols[state.currentProtocol].pages[state.currentPage].questions[state.currentQuestion]);
      const targetProtocolPage = state.protocols[action.targetProtocol].pages[action.targetPage];
      currentQuestionData.index = targetProtocolPage.questions.length;
      targetProtocolPage.questions.push(currentQuestionData);
      state.currentDuplicateProtocol = action.targetProtocol;
      state.currentDuplicatePage = action.targetPage;

      addToSaveQueue(state, state.currentProtocol, 'update');
      return { ...state };
    }

    case Actions.DELETE_QUESTION: {
      const page = state.protocols[state.currentProtocol].pages[state.currentPage];
      page.questions.splice(state.currentQuestion, 1);
      for (let index = 0; index < page.questions.length; index++) {
        const element = page.questions[index];
        element.index = index;
      }
      state.currentQuestion = '';

      addToSaveQueue(state, state.currentProtocol, 'update');
      return { ...state };
    }

    case Actions.DELETE_PROTOCOL_PAGE: {
      const pages = state.protocols[state.currentProtocol].pages;
      pages.splice(state.currentPage, 1);
      for (let index = 0; index < pages.length; index++) {
        const element = pages[index];
        element.index = index;
      }
      state.currentPage = pages.length - 1;
      state.currentQuestion = '';

      addToSaveQueue(state, state.currentProtocol, 'update');
      return { ...state };
    }

    case Actions.CLEAR_SAVE_QUEUE:
      return { ...state, saveQueue: {}, saveToFirebase: false };

    default:
      return state;
  }
}
