import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';

const mapStateToProps = state => ({
  protocols: state.protocols.protocols,
});

export default compose(connect(mapStateToProps, null))(InstrumentTableDiagnosis);

function InstrumentTableDiagnosis(props) {
  let propsTable = props.table;
  let propsTableData = props.tableData;
  let propsQuestion = props.question;
  if (propsTableData === undefined) return null;

  if (props.path !== undefined) {
    const ids = props.path.split('@');
    const instrument = props.protocols[ids[0]].pages[ids[1]].questions[ids[2]];
    const newInstrument = _.cloneDeep(instrument);
    const newRow = _.cloneDeep(instrument.table.rows[ids[3]]);
    newRow.index = 0;
    newInstrument.table.rows = [newRow];
    newInstrument.tableData = { r0: instrument.tableData['r' + ids[3]] };
    newInstrument.path = undefined;

    propsTable = newInstrument.table;
    propsTableData = newInstrument.tableData;
    propsQuestion = newInstrument.question;
  }

  const newTable = { rows: [], columns: propsTable.columns };
  for (let index = 0; index < propsTable.rows.length; index++) {
    const rowData = propsTable.rows[index];
    let hasCells = false;
    for (let cellIndex = 0; cellIndex < propsTable.columns.length; cellIndex++) {
      const column = propsTable.columns[cellIndex];
      let value =
        propsTableData !== undefined && propsTableData[index] !== undefined && propsTableData[index] !== null
          ? propsTableData[index][cellIndex]
          : undefined;

      //FIX TO A NEW FORMAT FOR PATIENT ANSWER. IT WAS AN ARRAY AND NOW IS AN OBJECT!!!
      if (value === undefined)
        value = propsTableData !== undefined && propsTableData['r' + index] !== undefined ? propsTableData['r' + index]['c' + cellIndex] : undefined;

      if (column.type === 'textfield') {
        if (value !== null && value !== undefined && value !== '') {
          hasCells = true;
          break;
        }
      }
      if (column.type === 'checkbox') {
        if (value === true) {
          hasCells = true;
          break;
        }
      }
    }
    if (hasCells) {
      newTable.rows.push(rowData);
    }
  }

  const drawCell = (row, column) => {
    let value = propsTableData[row.index] === undefined || propsTableData[row.index] === null ? '' : propsTableData[row.index][column.index];
    //FIX TO A NEW FORMAT FOR PATIENT ANSWER. IT WAS AN ARRAY AND NOW IS AN OBJECT!!!
    if (value === '') value = propsTableData['r' + row.index] === undefined ? '' : propsTableData['r' + row.index]['c' + column.index];

    if (column.type === 'textfield') return <Typography variant="caption">{value}</Typography>;
    let isChecked = value == 'true' || value === true;
    if (isChecked) return <Typography variant="caption">{column.label}</Typography>;
  };

  const drawRows = () => {
    const rows = [];

    for (let index = 0; index < newTable.rows.length; index++) {
      const rowData = newTable.rows[index];
      const cells = [];
      for (let cellIndex = 0; cellIndex < newTable.columns.length; cellIndex++) {
        const column = newTable.columns[cellIndex];
        cells.push(
          <TableCell key={props.index + ':' + rowData.index + ':' + cellIndex + ':cell'} align="center">
            {drawCell(rowData, column)}
          </TableCell>,
        );
      }
      rows.push(
        <TableRow key={props.index + ':' + rowData.index}>
          <TableCell>{rowData.label}</TableCell>
          {cells}
        </TableRow>,
      );
    }

    return rows;
  };

  const drawHeaderCells = () => {
    const output = [];
    for (let index = 0; index < newTable.columns.length; index++) {
      const element = newTable.columns[index];
      const label = element.type === 'textfield' ? element.label : '';
      output.push(
        <TableCell key={props.index + ':' + index} align="center">
          {label}
        </TableCell>,
      );
    }
    return output;
  };

  if (newTable.rows.length === 0) return null;
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body1">
                <b>{propsQuestion}</b>
              </Typography>
            </TableCell>
            {drawHeaderCells()}
          </TableRow>
        </TableHead>
        <TableBody>{drawRows()}</TableBody>
      </Table>
    </TableContainer>
  );
}
