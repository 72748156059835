import React from 'react';
import { MenuItem } from '@material-ui/core';
import ShortAnswerAdmin from './shortanswer/ShortAnswerAdmin';
import LongAnswerAdmin from './longanswer/LongAnswerAdmin';
import DateAdmin from './datetime/DateAdmin';
import MultipleChoiceAdmin from './multiplechoice/MultipleChoiceAdmin';
import CheckboxesAdmin from './checkboxes/CheckboxesAdmin';
import DropdownAdmin from './dropdown/DropdownAdmin';
import TableAdmin from './table/TableAdmin';
import InstrumentTableAdmin from './instrumenttable/InstrumentTableAdmin';

import ShortAnswerView from './shortanswer/ShortAnswerView';
import LongAnswerView from './longanswer/LongAnswerView';
import DateView from './datetime/DateView';
import MultipleChoiceView from './multiplechoice/MultipleChoiceView';
import CheckboxesView from './checkboxes/CheckboxesView';
import DropdownView from './dropdown/DropdownView';
import TableView from './table/TableView';
import InstrumentTableView from './instrumenttable/InstrumentTableView';

import { ShortAnswerDiagnosis } from './shortanswer/ShortAnswerView';
import { LongAnswerDiagnosis } from './longanswer/LongAnswerView';
import { DateDiagnosis } from './datetime/DateView';
import { MultipleChoiceDiagnosis } from './multiplechoice/MultipleChoiceView';
import { CheckboxesDiagnosis } from './checkboxes/CheckboxesView';
import { DropdownDiagnosis } from './dropdown/DropdownView';
import { TableDiagnosis } from './table/TableView';
import InstrumentTableDiagnosis from './instrumenttable/InstrumentTableDiagnosis';

export const SHORT_ANSWER = 'shortanswer';
export const LONG_ANSWER = 'longanswer';
export const DATE = 'date';
export const MULTIPLE_CHOICE = 'multiplechoice';
export const CHECKBOXES = 'checkboxes';
export const DROPDOWN = 'dropdown';
export const TABLE = 'table';
export const INSTRUMENT_TABLE = 'instrumenttable';

export const COMPONENTS_SELECT_LIST = [
  <MenuItem key={SHORT_ANSWER} value={SHORT_ANSWER}>
    Short Answer
  </MenuItem>,
  <MenuItem key={LONG_ANSWER} value={LONG_ANSWER}>
    Long Answer
  </MenuItem>,
  <MenuItem key={MULTIPLE_CHOICE} value={MULTIPLE_CHOICE}>
    Multiple choice
  </MenuItem>,
  <MenuItem key={CHECKBOXES} value={CHECKBOXES}>
    Checkboxes
  </MenuItem>,
  <MenuItem key={DROPDOWN} value={DROPDOWN}>
    Dropdown
  </MenuItem>,
  <MenuItem key={TABLE} value={TABLE}>
    Table
  </MenuItem>,
  <MenuItem key={INSTRUMENT_TABLE} value={INSTRUMENT_TABLE}>
    Instrument Table
  </MenuItem>,
  <MenuItem key={DATE} value={DATE}>
    Date
  </MenuItem>,
];

export function getComponentAdmin(config, setProperty) {
  switch (config.type) {
    case SHORT_ANSWER:
      return <ShortAnswerAdmin {...config} setProperty={setProperty} />;
      break;
    case LONG_ANSWER:
      return <LongAnswerAdmin {...config} setProperty={setProperty} />;
      break;
    case MULTIPLE_CHOICE:
      return <MultipleChoiceAdmin {...config} setProperty={setProperty} />;
      break;
    case DATE:
      return <DateAdmin {...config} setProperty={setProperty} />;
      break;
    case CHECKBOXES:
      return <CheckboxesAdmin {...config} setProperty={setProperty} />;
      break;
    case DROPDOWN:
      return <DropdownAdmin {...config} setProperty={setProperty} />;
      break;
    case TABLE:
      return <TableAdmin {...config} setProperty={setProperty} />;
      break;
    case INSTRUMENT_TABLE:
      return <InstrumentTableAdmin {...config} setProperty={setProperty} />;
      break;

    default:
      return null;
      break;
  }
}

export function getComponentView(config) {
  switch (config.type) {
    case SHORT_ANSWER:
      return <ShortAnswerView {...config} />;
      break;
    case LONG_ANSWER:
      return <LongAnswerView {...config} />;
      break;
    case MULTIPLE_CHOICE:
      return <MultipleChoiceView {...config} />;
      break;
    case DATE:
      return <DateView {...config} />;
      break;
    case CHECKBOXES:
      return <CheckboxesView {...config} />;
      break;
    case DROPDOWN:
      return <DropdownView {...config} />;
      break;
    case TABLE:
      return <TableView {...config} />;
      break;
    case INSTRUMENT_TABLE:
      return <InstrumentTableView {...config} />;
      break;

    default:
      return null;
      break;
  }
}

export function getDiagnosis(config) {
  switch (config.type) {
    case SHORT_ANSWER:
      return ShortAnswerDiagnosis(config);
      break;
    case LONG_ANSWER:
      return LongAnswerDiagnosis(config);
      break;
    case MULTIPLE_CHOICE:
      return MultipleChoiceDiagnosis(config);
      break;
    case DATE:
      return DateDiagnosis(config);
      break;
    case CHECKBOXES:
      return CheckboxesDiagnosis(config);
      break;
    case DROPDOWN:
      return DropdownDiagnosis(config);
      break;
    case TABLE:
      return TableDiagnosis(config);
      break;
    case INSTRUMENT_TABLE:
      return InstrumentTableDiagnosis(config);
      break;

    default:
      return null;
      break;
  }
}
