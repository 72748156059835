import { Grid, LinearProgress, TextField, Button, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { green } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Navigation from '../navigation';
import withAuthorization from '../session/withAuthorization';
import * as PatientActions from './PatientActions';
import ProtocolPages from './PatientProtocolNavigation';
import axios from 'axios';
import * as Components from '../protocol/components/Components';
import UploadButton from './UploadButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as AppActions from '../app/AppActions';

let data = new FormData();

function FileUpload(props) {
  const classes = useStyles();
  const [newUpload, setNewUpload] = React.useState({ date: moment().valueOf(), files: [], institution: '', diagnosis: '' });
  const [progress, setProgress] = React.useState(0);
  const [state, setState] = React.useState('add');

  async function onSubmit(e) {
    e.preventDefault();
    setState('upload');
    let res = await uploadFile();
  }

  function onSelectFiles(e) {
    data = new FormData();
    data.append('protocol_id', props.protocol.id);
    let files = [];
    for (let index = 0; index < e.length; index++) {
      data.append('files[]', e[index]);
      files.push(e[index].name);
    }

    setNewUpload({ ...newUpload, files: files });
  }

  async function uploadFile() {
    var config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
        console.log('Progress:-' + percentCompleted);
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post('https://diag.nika.rs/upload.php', data, config)
      .then(function(res) {
        console.log('UPLOAD COMPLETE');

        const newProtocol = { ...props.protocol };
        if (newProtocol.medicalRecords === undefined) newProtocol.medicalRecords = [];
        newProtocol.medicalRecords.push(newUpload);
        props.setProtocol({ ...newProtocol });

        setNewUpload({ date: moment().valueOf(), files: [], institution: '', diagnosis: '' });
        setProgress(0);
        setState('add');

        props.showSnack('success', 'NEW RECORD ADDED!');
      })
      .catch(function(err) {
        console.log(err.message);
      });
  }

  let selectedFilesNames = '';
  for (let index = 0; index < newUpload.files.length; index++) {
    selectedFilesNames += newUpload.files[index] + ', ';
  }

  let uploadButtonElement =
    newUpload.files.length === 0 ? (
      <UploadButton onChange={onSelectFiles} />
    ) : (
      <Typography>
        {selectedFilesNames}
        <Button onClick={e => setNewUpload({ ...newUpload, files: [] })}>
          <HighlightOffIcon />
        </Button>
      </Typography>
    );

  if (state === 'add')
    return (
      <Grid container direction="row" justify="space-around" alignItems="center" spacing={5}>
        <KeyboardDatePicker
          margin="normal"
          label="Datum"
          format="DD/MM/yyyy"
          value={newUpload.date}
          onChange={date => setNewUpload({ ...newUpload, date: date.valueOf() })}
        />
        <TextField
          required
          label="Institucija"
          value={newUpload.institution}
          onChange={event => setNewUpload({ ...newUpload, institution: event.target.value })}
        />
        <TextField
          required
          label="Diagnosis"
          value={newUpload.diagnosis}
          onChange={event => setNewUpload({ ...newUpload, diagnosis: event.target.value })}
        />
        {uploadButtonElement}
        <Button color="primary" variant="contained" onClick={onSubmit}>
          Snimi dokument
        </Button>
      </Grid>
    );
  else
    return (
      <Grid>
        <BorderLinearProgress variant="determinate" value={progress} />
      </Grid>
    );
}

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => {
  return {
    protocol: state.patients.patients[state.patients.currentPatient].protocols[state.patients.currentProtocol],
  };
};

const mapDispatchToProps = dispatch => ({
  setProtocol: value => dispatch({ type: PatientActions.SET_PROTOCOL, value }),
  showSnack: (severity, message) => dispatch({ type: AppActions.SET_SNACK, isOpen: true, severity, message }),
});

export default compose(withAuthorization(condition), connect(mapStateToProps, mapDispatchToProps))(FileUpload);

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 20,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
  },
  buttonContainer: {
    paddingLeft: theme.spacing(3),
  },
  outline: {
    borderStyle: 'solid',
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
    padding: theme.spacing(2),
  },
  formControl: {
    minWidth: 250,
  },
}));
