import moment from 'moment';
import * as Actions from './PatientActions';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

const defaultState = {
  currentPatient: '',
  currentProtocol: '',
  currentQuestion: -1,
  currentPage: 0,
  currentExamPage: 0,
  totalPatients: 120,
  saveToFirebase: false,
  lastUpdate: 1546376400,
  patients: {},
  saveQueue: {},
};

const defaultPatient = {
  name: 'Unesite ime i prezime',
  language: 'en',
  gender: 'Male',
  birthdate: '1990',
  city: 'Beograd',
  zip: '11000',
  phone: '+381 63',
  email: '@gmail.com',
  visit_count: 0,
  protocol_number: '',
  protocols: {},
};

const defaultProtocol = {
  date: moment().valueOf(),
  patient: '',
  type: 'therapy',
  protocol_id: '',
  protocol_number: '',
  anamnesis: '',
  checkedPages: [],
  medicalRecords: [],
  pages: [],
  therapy: 'POTREBNO JE:\n•\tFth., Kth.; \n•\tmirovanje dok traju terapije',
  conclusion: '​Uvidom u medicinsku dokumentaciju, anamnezu i funkcionalni status, trebalo bi da se u prvoj terapijskoj fazi sprovedu analgetske, relaksirajuće i revitalizirajuće fizikalne terapije, kao uvod u kineziterapiju (sistemsko rešenje).',
};

const addToSaveQueue = (state, patientId, action) => {
  if (state.saveQueue === undefined) state.saveQueue = {};
  state.saveQueue[patientId] = action;
  state.lastUpdate = moment().valueOf();
  state.saveToFirebase = true;
};

export default function PatientReducer(state = defaultState, action) {
  function alz(n) {
    if (n <= 9) {
      return '0' + n;
    }
    return n;
  }

  switch (action.type) {
    case Actions.LOAD_PATIENTS: {
      return {
        ...state,
        patients: action.value.patients,
        lastUpdate: action.value.lastUpdate,
        totalPatients: action.value.totalPatients,
        saveToFirebase: false,
      };
    }

    case Actions.ADD_PATIENT: {
      const newState = { ...state };
      const newPatient = _.cloneDeep(defaultPatient);

      newPatient.id = uuidv4();
      newPatient.protocol_number = action.index + '/' + moment().format('YY');

      if (newState.patients === undefined) newState.patients = {};
      newState.patients[newPatient.id] = newPatient;
      newState.currentPatient = newPatient.id;

      addToSaveQueue(newState, newPatient.id, 'update');
      return newState;
    }

    case Actions.SELECT_PATIENT:
      return { ...state, currentPatient: action.value, currentExamPage: 0, currentPage: 0, saveToFirebase: false };

    case Actions.DELETE_PATIENT: {
      const newState = { ...state };
      if (state.currentPatient === action.id) newState.currentPatient = '';
      delete newState.patients[action.id];

      addToSaveQueue(newState, action.id, 'delete');
      return newState;
    }

    case Actions.SET_PROPERTY: {
      const newState = { ...state };
      const newPatient = newState.patients[state.currentPatient];
      newPatient[action.property] = action.value;

      addToSaveQueue(newState, newPatient.id, 'update');
      return { ...state };
    }

    case Actions.ADD_PROTOCOL: {
      const newState = { ...state };
      const newProtocol = _.cloneDeep(defaultProtocol);
      newProtocol.id = uuidv4();
      newProtocol.patient = state.currentPatient;
      newProtocol.protocol_number = action.index;
      newProtocol.type = action.visitType;
      newProtocol.checkedPages = [];

      let now = new Date();
      newProtocol.date =
        now.getFullYear() +
        '-' +
        alz(now.getMonth() + 1) +
        '-' +
        alz(now.getDate()) +
        'T' +
        alz(now.getHours()) +
        ':' +
        alz(now.getMinutes()) +
        ':' +
        alz(now.getSeconds());

      if (newState.patients[state.currentPatient].protocols === undefined) newState.patients[state.currentPatient].protocols = {};
      newState.patients[state.currentPatient].protocols[newProtocol.id] = newProtocol;
      newState.currentProtocol = newProtocol.id;
      newState.currentPage = 0;
      newState.currentExamPage = 0;

      addToSaveQueue(newState, state.currentPatient, 'update');
      return newState;
    }

    case Actions.DUPLICATE_PROTOCOL: {
      const newState = { ...state };
      const newProtocol = _.cloneDeep(action.value);
      newProtocol.id = uuidv4();
      newProtocol.protocol_number = ++newProtocol.protocol_number;

      let now = new Date();
      newProtocol.date =
        now.getFullYear() +
        '-' +
        alz(now.getMonth() + 1) +
        '-' +
        alz(now.getDate()) +
        'T' +
        alz(now.getHours()) +
        ':' +
        alz(now.getMinutes()) +
        ':' +
        alz(now.getSeconds());

      _.forEach(newProtocol.pages, page => {
        _.forEach(page.questions, question => {
          delete question.patientAnswer;
        });
      });

      newState.patients[state.currentPatient].protocols[newProtocol.id] = newProtocol;
      newState.currentProtocol = newProtocol.id;
      newState.currentPage = 0;
      newState.currentExamPage = 1;
      if (newProtocol.pages.length === 0) newState.currentExamPage = 0;

      addToSaveQueue(newState, state.currentPatient, 'update');
      return newState;
    }

    case Actions.DELETE_PROTOCOL: {
      const newState = { ...state };
      delete newState.patients[state.currentPatient].protocols[action.id];

      addToSaveQueue(newState, state.currentPatient, 'update');
      return newState;
    }

    case Actions.SET_PROTOCOL: {
      const newState = { ...state };
      newState.patients[state.currentPatient].protocols[state.currentProtocol] = action.value;

      addToSaveQueue(newState, state.currentPatient, 'update');
      return newState;
    }

    case Actions.SET_PROTOCOL_PROPERTY: {
      const newState = { ...state };
      newState.patients[state.currentPatient].protocols[state.currentProtocol][action.pproperty] = action.value;

      addToSaveQueue(newState, state.currentPatient, 'update');
      return newState;
    }

    case Actions.SELECT_PROTOCOL:
      return { ...state, currentProtocol: action.value, currentPage: 0, currentExamPage: 0, saveToFirebase: false };

    case Actions.SELECT_PROTOCOL_PAGE:
      return { ...state, currentPage: action.value.protocolPage, currentExamPage: action.value.examPage, saveToFirebase: false };

    case Actions.ANSWER_QUESTION: {
      const newState = { ...state };
      newState.patients[state.currentPatient].protocols[state.currentProtocol].pages[state.currentPage].questions[action.value.index].patientAnswer =
        action.value.answer;

      addToSaveQueue(newState, state.currentPatient, 'update');
      return newState;
    }

    case Actions.REMOVE_QUESTION: {
      const newState = { ...state };
      const questions = newState.patients[state.currentPatient].protocols[state.currentProtocol].pages[state.currentPage].questions;
      questions.splice(action.value, 1);
      for (let index = 0; index < questions.length; index++) {
        questions[index].index = index;
      }

      addToSaveQueue(newState, state.currentPatient, 'update');
      return newState;
    }

    case Actions.SELECT_QUESTION: {
      const newState = { ...state };
      newState.currentQuestion = action.value;
      newState.saveToFirebase = false;
      return newState;
    }

    case Actions.SET_QUESTION_PROPERTY: {
      const newState = { ...state };
      newState.patients[state.currentPatient].protocols[state.currentProtocol].pages[state.currentPage].questions[state.currentQuestion][
        action.property
      ] = action.value;

      addToSaveQueue(newState, state.currentPatient, 'update');
      return { ...newState };
    }

    case Actions.CLEAR_SAVE_QUEUE:
      return { ...state, saveQueue: {}, saveToFirebase: false };

    default:
      return state;
  }
}
