import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from '../session/withAuthorization';
import { withFirebase } from '../firebase';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {
  Grid,
  Container,
  IconButton,
  Checkbox,
  Fab,
  Toolbar,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import TuneIcon from '@material-ui/icons/Tune';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Navigation from '../navigation';
import * as ProtocolActions from '../protocol/ProtocolActions';
import * as AppActions from '../app/AppActions';
import _ from 'lodash';
import RUSure from '../app/RUSure';

const AdminProtocols = props => {
  useEffect(() => {
    const onError = err => {
      console.log('Writing to Firebase error: ', err);
      props.showSnack('error', err);
    };

    //listen for changes in protocols
    props.firebase.lastProtocolsUpdate().on('value', snapshot => {
      //if local date is earlier than server date, download new state
      console.log('getting PROTOCOLS from FB', props.lastProtocolsUpdate, snapshot.val(), props.lastProtocolsUpdate < snapshot.val());
      if (props.user.isLoggedIn && props.lastProtocolsUpdate < snapshot.val()) {
        props.firebase
          .protocols()
          .once('value')
          .then(snapshot => {
            console.log('received PROTOCOLS');
            //props.showSnack('success', 'Loaded PROTOCOLS');
            props.loadProtocols(snapshot.val());
          })
          .catch(onError);
      }
    });

    // Specify how to clean up after this effect:
    return function cleanup() {
      console.log('stop listening for updates on Firebase');
      props.firebase.lastProtocolsUpdate().off();
    };
  }, [props.user.isLoggedIn, props.lastProtocolsUpdate]);


  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');
  if (props.protocolFilter === undefined) {
    let role = props.userRole;
    if (role === 'admin') role = 'all';
    props.selectFilter(role);
  }

  const protocols = () => {
    const output = [];
    let protocols = [];
    _.forIn(props.protocols, (value, key) => {
      protocols.push(_.cloneDeep(value));
    });
    protocols = _.sortBy(protocols, 'name');
    _.forEach(protocols, value => {
      if (value.name.toUpperCase().indexOf(searchText.toUpperCase()) >= 0)
        if (props.protocolFilter === 'all' || value.type === props.protocolFilter)
          output.push(
            <TableRow key={value.id}>
              <TableCell component="th" scope="row">
                <Link key={value.id} onClick={() => props.select(value.id)} to={ROUTES.ADMIN_PROTOCOL_PAGE}>
                  <GreenCheckbox checked={value.published} />
                  {value.name}
                </Link>
              </TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => {
                    setDeleteProtocol(value.id);
                    setIsDeleteOpen(true);
                  }}
                >
                  <DeleteOutlineIcon />
                </Button>
              </TableCell>
            </TableRow>,
          );
    });
    return output;
  };

  const [deleteProtocol, setDeleteProtocol] = React.useState('');
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  let menuItems = () => {
    const output = [];
    if (props.userRole == 'admin')
      output.push(
        <Select value={props.protocolFilter} onChange={event => props.selectFilter(event.target.value)}>
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="template">Template</MenuItem>
          <MenuItem value="exam">Exam</MenuItem>
          <MenuItem value="therapy">Physical Therapy</MenuItem>
          <MenuItem value="kinesiotherapy">Kinesiotherapy</MenuItem>
          <MenuItem value="grouptherapy">Group Exercises</MenuItem>
          <MenuItem value="instruments">Instruments</MenuItem>
          <MenuItem value="somatoped">Somatoped</MenuItem>
          <MenuItem value="logoped">Logoped</MenuItem>
          <MenuItem value="psiholog">Psiholog</MenuItem>
          <MenuItem value="endokrinolog">Endokrinolog</MenuItem>
        </Select>,
      );
    if (props.userRole != 'admin') {
      output.push(
        <Select value={props.userRole} onChange={event => props.selectFilter(event.target.value)}>
          <MenuItem value={props.userRole}>{props.userRole}</MenuItem>
        </Select>,
      );
      if (props.protocolFilter != props.userRole) props.selectFilter(props.userRole);
    }

    return output;
  };

  return (
    <Container maxWidth="xl">
      <Navigation title="Admin Protocols" />
      <Toolbar>
        <Typography>Filter by type:</Typography>
        {menuItems()}
        <div className={classes.divider} />
        <Input placeholder="Search by name" onChange={event => setSearchText(event.target.value)} />
        <div className={classes.grow} />
        <Fab onClick={() => props.add(props.protocolFilter)} color="secondary" size="small" className={classes.fabGreen}>
          <AddIcon />
        </Fab>
      </Toolbar>
      <Grid container direction="column" justify="flex-start" alignItems="stretch">
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{protocols()}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <RUSure isOpen={isDeleteOpen} setModal={setIsDeleteOpen} delete={() => props.delete(deleteProtocol)} />
    </Container>
  );
}

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => ({
  currState: state,
  protocols: state.protocols.protocols,
  protocolFilter: state.protocols.currentProtocolFilter,
  userRole: state.user.role,
  lastProtocolsUpdate: state.protocols.lastUpdate,
});

const mapDispatchToProps = dispatch => ({
  add: protocolType => dispatch({ type: ProtocolActions.ADD_PROTOCOL, protocolType }),
  select: value => dispatch({ type: ProtocolActions.SELECT_PROTOCOL, value }),
  delete: value => dispatch({ type: ProtocolActions.DELETE_PROTOCOL, value }),
  selectFilter: value => dispatch({ type: ProtocolActions.SELECT_PROTOCOL_FILTER, value }),
  showSnack: (severity, message) => dispatch({ type: AppActions.SET_SNACK, isOpen: true, severity, message }),
  loadProtocols: value => dispatch({ type: ProtocolActions.LOAD_PROTOCOLS, value }),
});

export default compose(withFirebase, withAuthorization(condition), connect(mapStateToProps, mapDispatchToProps))(AdminProtocols);

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  divider: { width: '20px' },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));
