import { FormControlLabel, Grid, Switch, TextField, Typography, Select, MenuItem } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from '../session/withAuthorization';
import AdminProtocolPageAdmin from './AdminProtocolPageAdmin';
import * as ProtocolActions from './ProtocolActions';

function AdminProtocol(props) {
  if (props.protocol === undefined) return <div />;

  const classes = useStyles();
  if (props.protocol.type === undefined) props.setProperty('type', 'template');

  let menuItems = () => {
    const output = [];
    if (props.userRole == 'admin')
      output.push(
        <FormControlLabel
          control={
            <Select value={props.protocol.type} onChange={event => props.setProperty('type', event.target.value)}>
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="template">Template</MenuItem>
              <MenuItem value="exam">Exam</MenuItem>
              <MenuItem value="therapy">Physical Therapy</MenuItem>
              <MenuItem value="kinesiotherapy">Kinesiotherapy</MenuItem>
              <MenuItem value="grouptherapy">Group Exercises</MenuItem>
              <MenuItem value="instruments">Instruments</MenuItem>
              <MenuItem value="somatoped">Somatoped</MenuItem>
              <MenuItem value="logoped">Logoped</MenuItem>
              <MenuItem value="psiholog">Psiholog</MenuItem>
              <MenuItem value="endokrinolog">Endokrinolog</MenuItem>
            </Select>
          }
          label="Type"
          labelPlacement="top"
        />,
      );
    if (props.userRole != 'admin') {
      output.push(
        <FormControlLabel
          control={
            <Select value={props.protocol.type} onChange={event => props.setProperty('type', event.target.value)}>
              <MenuItem value={props.userRole}>{props.userRole}</MenuItem>
            </Select>
          }
          label="Type"
          labelPlacement="top"
        />,
      );
    }

    return output;
  };

  return (
    <Grid container direction="column" justify="center" alignItems="stretch" spacing={3} className={classes.container}>
      <Grid item>
        <Typography variant="h4">Protocol</Typography>
        <TextField
          required
          label="Protocol name"
          placeholder="Enter Name"
          variant="outlined"
          fullWidth
          defaultValue={props.protocol.name}
          onBlur={event => props.setProperty('name', event.target.value)}
        />
        <FormControlLabel
          control={<GreenSwitch checked={props.protocol.published} onChange={event => props.setProperty('published', event.target.checked)} />}
          label="Published"
          labelPlacement="top"
        />
        {menuItems()}
      </Grid>
      <Grid item>
        <Typography variant="h4">Pages</Typography>
        <AdminProtocolPageAdmin
          currentProtocol={props.currentProtocol}
          currentPage={props.currentPage}
          pages={props.protocol.pages}
          addPage={props.addPage}
          setProperty={props.setProperty}
          selectPage={props.selectPage}
          deletePage={props.deletePage}
        />
      </Grid>
    </Grid>
  );
}

const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => ({
  protocol: state.protocols.protocols[state.protocols.currentProtocol],
  currentProtocol: state.protocols.currentProtocol,
  currentPage: state.protocols.currentPage,
  currState: state,
  userRole: state.user.role,
});

const mapDispatchToProps = dispatch => ({
  addPage: () => dispatch({ type: ProtocolActions.ADD_PROTOCOL_PAGE }),
  setProperty: (property, value) => dispatch({ type: ProtocolActions.SET_PROPERTY, property: property, value }),
  selectPage: value => dispatch({ type: ProtocolActions.SELECT_PROTOCOL_PAGE, value }),
  deletePage: value => dispatch({ type: ProtocolActions.DELETE_PROTOCOL_PAGE, value }),
});

export default compose(withAuthorization(condition), connect(mapStateToProps, mapDispatchToProps))(AdminProtocol);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    borderBottomStyle: 'solid',
    borderBottomColor: '#cccccc',
    borderBottomWidth: 2,
    marginBottom: theme.spacing(3),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));
