import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import ProtocolOverviewItem from '../../../patient/ProtocolOverviewItem';

export default function DropdownView(props) {
  const patientAnswer = props.patientAnswer === undefined ? '' : props.patientAnswer;

  const drawAnswers = () => {
    const answers = [];
    for (let index = 0; index < props.answers.length; index++) {
      const element = props.answers[index];
      answers.push(
        <MenuItem key={props.index + ':' + index} value={element.answer}>
          {element.answer}
        </MenuItem>,
      );
    }
    return answers;
  };

  return (
    <Select
      fullWidth
      labelId="outlined-label"
      value={patientAnswer}
      onChange={event => props.answer({ index: props.index, answer: event.target.value })}
    >
      {drawAnswers()}
    </Select>
  );
}

export function DropdownDiagnosis(props) {
  if (props.patientAnswer !== undefined && props.patientAnswer !== '')
    return <ProtocolOverviewItem question={props.question} answer={props.patientAnswer} />;
  else return null;
}
