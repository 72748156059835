import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

export default function UploadButton(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input className={classes.input} id="contained-button-file" multiple type="file" onChange={event => props.onChange(event.target.files)} />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="secondary" component="span">
          Datoteke
        </Button>
      </label>
    </div>
  );
}
