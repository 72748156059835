import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../firebase';
import * as Actions from '../../config/globalActions';
import { Button } from '@material-ui/core';

const SignOutButton = props => {
  return (
    <Button style={{ borderRadius: 10 }} onClick={props.logoutUser}>
      Odjavi se
    </Button>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch({ type: Actions.USER_LOGOUT_ACTION }),
});

export default compose(withFirebase, connect(mapStateToProps, mapDispatchToProps))(SignOutButton);
