import * as Actions from './AppActions';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import moment from 'moment';

const defaultState = {
  snack: { isOpen: false, severity: 'error', message: 'Default error' },
};

export default function AppReducer(state = defaultState, action) {
  switch (action.type) {
    case Actions.SET_SNACK: {
      return { ...state, snack: { isOpen: action.isOpen, severity: action.severity, message: action.message } };
    }

    default:
      return state;
  }
}
