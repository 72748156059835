import { Grid, Paper, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { compose } from 'recompose';
import withAuthorization from '../session/withAuthorization';

function ProtocolOverviewItem(props) {
  const classes = useStyles();

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Typography variant="body1">
        <b>{props.question}</b>
      </Typography>
      <Box className={classes.container} />
      <Typography variant="body2" display="inline">
        {props.answer}
      </Typography>
    </Grid>
  );
}

const condition = authUser => {
  return !!authUser;
};

export default compose(withAuthorization(condition))(ProtocolOverviewItem);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
}));
