import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from '../session/withAuthorization';
import { Grid, InputLabel, TextField, Paper, Button, Select, FormControl, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import Navigation from '../navigation';
import _ from 'lodash';
import * as ProtocolActions from './ProtocolActions';
import * as Components from './components/Components';
import AdminProtocol from './AdminProtocol';
import DuplicateModal from './DuplicateModal';
import RUSure from '../app/RUSure';

function AdminProtocolPage(props) {
  const classes = useStyles();
  const drawViewComponent = question => {
    const config = { ...question };
    config.answer = () => {
      console.log('Ignoring answering from protocol design page.');
    };
    return Components.getComponentView(config);
  };

  const questions = () => {
    const output = [];
    const keyPrefix = props.currentProtocol + props.currentPage;
    _.forEach(props.questions, function(value) {
      //if selected, show the admin form
      if (value.index === props.currentQuestion) {
        output.push(
          <Grid item key={keyPrefix + value.index}>
            <AdminQuestion
              keyPrefix={keyPrefix + value.index}
              question={value}
              questionsLength={props.questions.length}
              setProperty={props.setProperty}
              moveUp={props.moveUp}
              moveDown={props.moveDown}
              moveToIndex={props.moveToIndex}
              duplicate={props.duplicate}
              delete={props.delete}
            />
          </Grid>,
        );
      } else {
        //if not selected, show as view
        output.push(
          <Grid item key={keyPrefix + value.index}>
            <Paper className={classes.container} onClick={() => props.selectQuestion(value.index)}>
              <Typography variant="h6" gutterBottom>
                {value.question}
              </Typography>
              {drawViewComponent(value)}
            </Paper>
          </Grid>,
        );
      }
    });
    return output;
  };

  return (
    <Grid container direction="column" justify="flex-start" alignItems="stretch">
      <Navigation title="Admin Protocol Page" />
      <Grid item>
        <AdminProtocol />
      </Grid>
      <Grid item className={classes.grow}>
        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3} className={classes.container}>
          <Typography variant="h5" align="center">
            {props.pageName}
          </Typography>
          {questions()}
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <Button onClick={() => props.addQuestion()} color="secondary" aria-label="add" size="small" className={classes.fabGreen}>
            <AddIcon /> Add Another Question
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function AdminQuestion(props) {
  const classes = useStyles();
  const [isDuplicateOpen, setIsDuplicateOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  const drawAdminComponent = () => {
    return Components.getComponentAdmin(props.question, props.setProperty);
  };

  return (
    <Paper elevation={3} className={classes.container}>
      <Grid container direction="column" justify="flex-start" alignItems="stretch">
        <Grid item>
          <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
            <Grid item xs={6}>
              <TextField
                required
                key={props.keyPrefix + 'text'}
                label="Question"
                placeholder="Enter question text"
                variant="outlined"
                fullWidth
                value={props.question.question}
                onChange={event => props.setProperty('question', event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl variant="outlined">
                <InputLabel id="outlined-label">Type</InputLabel>
                <Select
                  fullWidth
                  labelId="outlined-label"
                  label="Type"
                  value={props.question.type}
                  onChange={event => props.setProperty('type', event.target.value)}
                >
                  {Components.COMPONENTS_SELECT_LIST}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button disabled={props.question.index === 0 ? true : false} onClick={() => props.moveUp()}>
                <ArrowUpwardIcon />
              </Button>
              <TextField
                className={classes.indexTextField}
                key={props.keyPrefix + 'index'}
                defaultValue={props.question.index + 1}
                onBlur={event => props.moveToIndex(event.target.value - 1)} 
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
              <Button disabled={props.question.index === props.questionsLength - 1 ? true : false} onClick={() => props.moveDown()}>
                <ArrowDownwardIcon />
              </Button>
              <Button onClick={() => setIsDuplicateOpen(true)}>
                <FilterNoneIcon />
              </Button>
              <Button onClick={() => setIsDeleteOpen(true)}>
                <DeleteOutlineIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{drawAdminComponent()}</Grid>
      </Grid>
      <DuplicateModal isOpen={isDuplicateOpen} setModal={setIsDuplicateOpen} />
      <RUSure isOpen={isDeleteOpen} setModal={setIsDeleteOpen} delete={props.delete} />
    </Paper>
  );
}

const condition = authUser => {
  return !!authUser;
};

const mapStateToProps = state => ({
  questions: state.protocols.protocols[state.protocols.currentProtocol]
    ? state.protocols.protocols[state.protocols.currentProtocol].pages[state.protocols.currentPage].questions
    : undefined,
  pageName: state.protocols.protocols[state.protocols.currentProtocol]
    ? state.protocols.protocols[state.protocols.currentProtocol].pages[state.protocols.currentPage].name
    : undefined,
  currentProtocol: state.protocols.currentProtocol,
  currentPage: state.protocols.currentPage,
  currentQuestion: state.protocols.currentQuestion,
  currState: state,
});

const mapDispatchToProps = dispatch => ({
  addQuestion: () => dispatch({ type: ProtocolActions.ADD_PROTOCOL_QUESTION }),
  selectQuestion: value => dispatch({ type: ProtocolActions.SELECT_PROTOCOL_QUESTION, value }),
  setProperty: (property, value) => dispatch({ type: ProtocolActions.SET_QUESTION_PROPERTY, property: property, value }),
  moveUp: () => dispatch({ type: ProtocolActions.MOVE_QUESTION_UP }),
  moveDown: () => dispatch({ type: ProtocolActions.MOVE_QUESTION_DOWN }),
  moveToIndex: value => dispatch({ type: ProtocolActions.MOVE_QUESTION_TO_INDEX, value }),
  duplicate: value => dispatch({ type: ProtocolActions.DUPLICATE_QUESTION }),
  delete: value => dispatch({ type: ProtocolActions.DELETE_QUESTION }),
});

export default compose(withAuthorization(condition), connect(mapStateToProps, mapDispatchToProps))(AdminProtocolPage);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
  },
  buttonContainer: {
    paddingLeft: theme.spacing(3),
  },
  outline: {
    borderStyle: 'solid',
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  indexTextField: {
    width: 20
  },
  grow: {
    flexGrow: 1,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));
