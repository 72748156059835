import { Button, Grid, Paper, Typography, Box } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { red } from '@material-ui/core/colors';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import _ from 'lodash';
import CheckboxTree from '../../../patient/CheckTree/CheckboxTree';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from '../../../session/withAuthorization';
import * as ProtocolActions from '../../ProtocolActions';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
  },
  paper: {
    padding: theme.spacing(5),
  },
  divider: {
    height: 20,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: red[900],
    '&:hover': {
      backgroundColor: red[900],
    },
  },
}));

function InstrumentModal(props) {
  const classes = useStyles();
  const buttonLabel = props.buttonLabel !== undefined ? props.buttonLabel : 'Izaberi';

  let protocols = [];
  _.forIn(props.protocols.protocols, function(value, key) {
    if (value.published && value.type === 'instruments') protocols.push({ id: value.id, name: value.name, pages: value.pages });
  });
  protocols = _.sortBy(protocols, 'name');

  const defaultExpanded = props.expanded !== undefined ? props.expanded : [protocols[0].id];
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const [checked, setChecked] = React.useState([]);

  const nodes = () => {
    const output = [];
    _.forEach(protocols, value => {
      let protocol = { value: value.id, label: value.name, children: [] };
      _.forEach(value.pages, page => {
        let pageChild = { value: value.id + '@' + page.index, label: page.name, children: [] };
        _.forEach(page.questions, question => {
          if (question.type === 'instrumenttable') {
            let questionData = { value: value.id + '@' + page.index + '@' + question.index, label: question.question, children: [] };
            _.forEach(question.table.rows, row => {
              questionData.children.push({ value: value.id + '@' + page.index + '@' + question.index + '@' + row.index, label: row.label });
            });
            pageChild.children.push(questionData);
          }
        });
        protocol.children.push(pageChild);
      });
      output.push(protocol);
    });
    return output;
  };

  const handleCheckedChange = (checked, targetNode) => {
    let newChecked = [];
    newChecked.push(targetNode.value);
    setChecked(newChecked);
  };

  return (
    <Modal
      className={classes.modal}
      open={props.isOpen.isOpen}
      onClose={() => props.setModal({ isOpen: false, index: 0 })}
      BackdropComponent={Backdrop}
    >
      <Paper className={classes.paper}>
        <Grid container direction="column" justify="center" alignItems="stretch">
          <Typography variant="h5">Izaberite aparat i program</Typography>
          <Typography variant="body1">Neko uputstvo</Typography>
          <Box className={classes.divider} />
          <Box className={classes.divider} />
          <CheckboxTree
            iconsClass="fa5"
            onlyLeafCheckboxes
            nodes={nodes()}
            checked={checked}
            expanded={expanded}
            onCheck={handleCheckedChange}
            onExpand={expanded => setExpanded(expanded)}
          />
          <Button
            className={classes.fabGreen}
            onClick={() => {
              const ids = checked[0].split('@');
              const instrument = protocols[0].pages[ids[1]].questions[ids[2]];

              const newInstrument = _.cloneDeep(instrument);
              const newRow = _.cloneDeep(instrument.table.rows[ids[3]]);
              newRow.index = 0;
              newInstrument.table.rows = [newRow];
              newInstrument.tableData = { r0: instrument.tableData['r' + ids[3]] };
              newInstrument.path = checked[0];

              props.answers[props.isOpen.index].answer = newInstrument;

              props.setProperty('answers', props.answers);
              props.setProperty('expanded', expanded);
              props.setModal({ isOpen: false, index: 0 });
            }}
          >
            {buttonLabel}
          </Button>
          <Box className={classes.divider} />
          <Button
            onClick={() => {
              props.setModal({ isOpen: false, index: 0 });
            }}
          >
            Odustani
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
}

const mapStateToProps = state => ({
  protocols: state.protocols,
});

const mapDispatchToProps = dispatch => ({
  setProperty: (property, value) => dispatch({ type: ProtocolActions.SET_QUESTION_PROPERTY, property: property, value }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InstrumentModal);
