import { createMuiTheme } from '@material-ui/core/styles';
import { orange, purple, green, red } from '@material-ui/core/colors';

export const theme = createMuiTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      main: green[600],
    },
    secondary: {
      main: '#932d2d',
    },
  },
});
